import React, { useState } from "react";
import { useEffect } from "react";

import { mainApi } from "../../../../components/utils/main-api";
import Pagination from "../../../../components/pagionation/pagination";
import PaginationLimit from "../../../../components/PaginationLimit/paginationLimit";
import { formatNumber } from "../../../../helpers/price";
import "./upgrades.css";
import { ReactComponent as Status } from "../../../../assets/icons/history-status.svg";
import { ReactComponent as Defeat } from "../../../../assets/icons/defeat.svg";
import { ReactComponent as Arrow } from "../../../../assets/icons/arrow-green.svg";
import Preloader from "../../../../components/preloader/preloader";
import Loader from "../../../../components/loader/loader";

function Upgrades() {
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true); 
  const [dataLength, setDataLength] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [limit, setLimit] = useState({ value: '10', label: '10' });

  const getUpgradeItems = (limit, offset) => {
    mainApi
      .upgradeUserItemsUpgrdeHistory(limit, offset)
      .then((data) => {
        setItems(data.results);
        setDataLength(data.count);
      })
      .catch((error) => {
        console.log("error: ", error);
      })
      .finally(() => {
        setIsLoading(false); 
      });
  }

  useEffect(() => {
    getUpgradeItems(limit.value, currentPage * limit.value)
  }, [currentPage, limit]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    
    <div className="profile_content_wrapper upgrade_content">
      <div className="upgrade_items_list">

      {items.length === 0 ? ( // Проверяем, пуст ли массив items
      <div className="empty_message">
        <h2>Тут пусто...</h2>
        <p>
          Создавайте Апгрейды <a href="/upgrade">на этой странице</a>
        </p>
      </div>
    ) : (
        items && items.map((items, i) => (
          <div className="upgrade_list_item" key={i}>
            {items.success ? (
              <>
                {items.balance ? (
                  <div className="">
                    <p className="profile_items_name upgrade_balance_p">{formatNumber(items.balance || 0)} ₽</p>
                  </div>
                ): (
                  <div className="upgrade_list_item_img">
                    <img src={items.upgraded[0]?.image} alt="" />
                  </div>
                )}
                <div className="upgrade_list_item_svg">
                  <Arrow  className="defeat_icon" />
                </div>
                <div className="upgrade_list_item_img">
                  <img src={items.desired[0]?.image} alt="" />
                </div>
                <div className="upgrade_list_item_des">
                  <p className="profile_items_name">{formatNumber(items.desired[0]?.price || 0)} ₽</p>
                  <div>
                    <p className="history_item_descr">{items.desired[0]?.name}</p>
                    <p className="history_status status1">Получен в апгрейде</p>
                  </div>
                </div>
              </>
              ) : (
                <>
                  {items.balance ? (
                    <div className="upgrade_list_item_img">
                      <p className="profile_items_name">{formatNumber(items.balance || 0)} ₽</p>
                      <Defeat className="defeat_icon" />
                    </div>
                  ): (
                    <div className="upgrade_list_item_img">
                      <img src={items.upgraded[0]?.image} alt="" />
                      <Defeat className="defeat_icon" />
                    </div>
                  )}
                  <div className="upgrade_list_item_des">
                    <p className="profile_items_name">{formatNumber(items.upgraded[0]?.price || 0)} ₽</p>
                    <div>
                      <p className="history_item_descr">{items.upgraded[0]?.name}</p>
                        <p className="history_status status3">
                          Сгорел в апгрейде{" "} <Status />
                        </p>
                    </div>
                  </div>
                </>
              )}
          </div>
        ))
      )}
      </div>
      {items.length !==0 && (<div className="">
          <div className="cases_paginations">
            <Pagination
              pageCount={Math.ceil(dataLength / limit.value)}
              onPageChange={setCurrentPage}
              currentPage={currentPage}
            />
            </div>
            <div className="">
              <PaginationLimit limit={limit} setLimit={setLimit} onPageChange={setCurrentPage} />
            </div>
          </div>
        )}
    </div>
  );
}

export default Upgrades;
