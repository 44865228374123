import { useDispatch } from "react-redux";
import { useState } from "react";

import { modalBonusCodeClearAction } from "../../redux/modal-bonuscode-reducer";

import { ReactComponent as ExitIcon } from "../../assets/icons/close-icon.svg";

import { mainApi } from "../utils/main-api";

import { snackbarAction, snackbarClearAction } from "../../redux/snackbar-reducer";

import "./modal-bonuscode.css";

function ModalBonusCode() {
  const dispatch = useDispatch();

  const [promoCode, setPromoCode] = useState("");


  const snackbarActions = (snackText, error) => {
    dispatch(snackbarAction({snackText, error}))
    setTimeout(() => {
      dispatch(snackbarClearAction())
    }, 2000);
  };

  const promoPOST = () => {
    mainApi
      .promoPOST({ 
        code_data: promoCode,
      })
      .then((res) => {
        const { code, message } = res;
        setPromoCode(code || "");
        snackbarActions(message || "Промокод активирован!");
        dispatch(modalBonusCodeClearAction())
      })
      .catch((error) => {
        const errorMessage = error.response?.data?.message || error.message || "Ошибка активации промокода";
        snackbarActions(errorMessage, true);
      
        const errorNotFined = error.response?.data?.non_field_errors || error.non_field_errors || errorMessage;
        snackbarActions(errorNotFined, true); 
      });
  };

  return (
    <div className="modal_overlay">
      <div className="modal_wrapper_template">
        <div className="modal_template login_modal">
          <div className="modal_header_shop">
            <span className="modal_header_shop-name">Активация бонус кода</span>
            <button 
            className="modal_closer"
            onClick={() => dispatch(modalBonusCodeClearAction())}
            >
              <ExitIcon />
            </button>
          </div>
          <div className="aouth_login_inputs">
            <div className="auth_password_input">
              <input
                type="text"
                className="aouth_login_input"
                placeholder="Введите бонус код"
                value={promoCode}
                onChange={(e) => setPromoCode(e.target.value)}
              />
            </div>
          </div>
          <div className="modal_btns_shop">
            <button className="green_btns" onClick={promoPOST}>
              Активировать
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalBonusCode;