import React, { Suspense } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

import _ from 'lodash';

import { mainApi } from "../../../../components/utils/main-api";

import Loader from "../../../../components/loader/loader";

import Pagination from "../../../../components/pagionation/pagination";
import PaginationLimit from "../../../../components/PaginationLimit/paginationLimit";
import { snackbarAction, snackbarClearAction } from "../../../../redux/snackbar-reducer";

import "./items.css";
import fav_case from "../../../../assets/images/fav-case.png";
import best_case from "../../../../assets/images/best-case.png";
// import { ReactComponent as Loading } from "../../../../assets/icons/loading.svg";
import { loginUserAction } from "../../../../redux/user-reducer";
import { formatNumber } from "../../../../helpers/price";
import ItemCardProfile from "../../../../components/itemcard-profile/itemcard-profile";


function Items() {
  const dispatch = useDispatch();
  const usersData = useSelector((state) => state.user.user);
  const [isLoading, setIsLoading] = useState(true);
  const [items, setItems] = useState([]);
  const [dataLength, setDataLength] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [limit, setLimit] = useState({ value: '10', label: '10' });
  const [isProcessing, setIsProcessing] = useState(false);

  const handleOpenCase = () => {
    const casePath = `/case/${usersData.favorite_case.translit_name}`;
    window.location.href = casePath;
  };

  const snackbarActions = (snackText, error) => {
    dispatch(snackbarAction({snackText, error}))
    setTimeout(() => {
      dispatch(snackbarClearAction())
    }, 2000);
  };

  const getUserItems = (limit, offset) => {
    mainApi
      .userItems(limit, offset)
      .then((data) => {
        setItems(data.results);
        setDataLength(data.count);
      })
      .catch((error) => {
        console.log("error: ", error);
      })
      .finally(() => {
        setIsLoading(false); 
      });
  };

  const saleItems = _.debounce((id, price) => {
    if (isProcessing) return;
    setIsProcessing(true);

    mainApi
      .deleteUserItemsAction(id)
      .then(() => {
      })
      .catch((error) => {
        dispatch(loginUserAction({ ...usersData, balance: usersData.balance + price }));
        snackbarActions(`Предмет продан на сумму ${formatNumber(price)} р.`);
      })
      .finally(() => {
        getUserItems(limit.value, currentPage * limit.value);
        setIsProcessing(false);
      });
  }, 100);


  const outputs = (items) => {
    if (usersData.player_id === null) {
      snackbarActions("error", {error: 'Заполните поле UID'});
    } else {
      const body = {
        "player_id": usersData.player_id,
        "output_items": [
          {
            "item_id": `${items.id}`
          }
        ]
      }
      mainApi
        .outputsPostAction(body)
        .then((data) => {
          getUserItems(limit.value, currentPage * limit.value);
        })
        .catch((error) => {
          console.log("error", error);
          snackbarActions("error", error);
        });
    }
  }

  const saleAll = _.debounce(() => {
    const totalPrice = items.reduce((acc, item) => acc + item.price, 0);
  
    mainApi
      .deleteUserItemsAllAction()
      .then(() => {
      })
      .catch((error) => {
        snackbarActions(`Все предметы проданы на сумму ${formatNumber(totalPrice)} ₽`);
        dispatch(loginUserAction({ ...usersData, balance: usersData.balance + totalPrice }));
      })
      .finally(() => {
        getUserItems(limit.value, currentPage * limit.value);
      });
  }, 500);
  
  const handleSaleAllClick = () => {
    saleAll();
  };
  
  useEffect(() => {
    getUserItems(limit.value, currentPage * limit.value);
  }, [currentPage, limit]);



  return (

    <div className="profile_content_wrapper items_content">
    <div className="profile_bests">
  <div className="profile_best_item">
    <div className="profile_best_item_content">
      <div className="profile_best_item_description">
        <h4>ЛЮБИМЫЙ КЕЙС</h4>
        <p>{usersData?.favorite_case?.name || "Нет данных"}</p>
      </div>
      <button 
        className="open_case_profile" 
        onClick={usersData?.favorite_case ? handleOpenCase : () => {}}
        disabled={!usersData?.favorite_case}
      >
        ОТКРЫТЬ
      </button>
    </div>
    <div className="profile_best_img">
      <img 
        src={usersData?.favorite_case?.image || "путь_к_изображению_по_умолчанию"} 
        alt="Любимый кейс" 
      />
    </div>
  </div>

  <div className="profile_best_item">
    <div className="profile_best_item_content">
      <div className="profile_best_item_description">
        <h4>ЛУЧШИЙ ДРОП</h4>
        <p>{usersData?.best_drop?.name || "Нет данных"}</p>
      </div>
      <div className="case_price_profile">
        <p>{usersData?.best_drop?.price ? `${usersData.best_drop.price}₽` : "—"}</p>
      </div>
    </div>
    <div className="profile_best_img">
      <img 
        src={usersData?.best_drop?.image || "путь_к_изображению_по_умолчанию"} 
        alt="Лучший дроп" 
      />
    </div>
  </div>
</div>

{isLoading ? (
    <div className="loader-container">
      <Loader />
    </div>
  ) : (
    <>
      {items.length === 0 ? (
        <div className="empty_message">
          <h2>Ваш инветарь пуст</h2>
          <p>
            Нужно это исправить!
          </p>
        </div>
      ) : (
        <>
          <div className="btn_sale_all" onClick={handleSaleAllClick}>
            <button>
                Продать всё за {formatNumber(items.filter(item => !item.withdrawal_process).reduce((acc, item) => acc + item.price, 0))} ₽
            </button>
          </div>

          <div className="profile_items_list">
            {items.map((item) => (
              <ItemCardProfile
                key={item.id}
                items={item}
                outputs={outputs}
                saleItems={saleItems}
              />
            ))}
          </div>

          <div className="cases_paginations">
            <Pagination
              pageCount={Math.ceil(dataLength / limit.value)}
              onPageChange={setCurrentPage}
              currentPage={currentPage}
            />
          </div>
          <div>
            <PaginationLimit
              limit={limit}
              setLimit={setLimit}
              onPageChange={setCurrentPage}
            />
          </div>
        </>
      )}
    </>
  )}
      
    </div>
  );
}

export default Items;
