import React, { useEffect, useRef} from "react";
import vk_icon from "../../../assets/icons/auth-icons/vk-icon.png";
import { mainApi } from "../../utils/main-api";
import { useDispatch } from "react-redux";
import { loginUserAction } from "../../../redux/user-reducer";
import { useNavigate } from "react-router-dom";

function VKFloatingLoginComponent({setLoginModal}) {
    const dispatch = useDispatch();
    const navigate = useNavigate();

  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://unpkg.com/@vkid/sdk@<3.0.0/dist-sdk/umd/index.js";
    script.async = true;

    script.onload = () => {
      console.log('VK ID SDK успешно загружен');
      if (window.VKIDSDK) {
        const VKID = window.VKIDSDK;

        VKID.Config.init({
          app: 52816691,
          redirectUrl: 'https://primoloot.com/',
          responseMode: VKID.ConfigResponseMode.Callback,
          source: VKID.ConfigSource.LOWCODE,
          codeVerifier: "PPhZUcoC8aZjteUHKRd5zUNaUiH18MoZy9CJwv9yG7CVU",
        });
        
        const oneTap = new VKID.OneTap();

        oneTap
          .render({
            container: document.getElementById('vk-one-tap'),
            showAlternativeLogin: false,
            fastAuthEnabled: false,
          })
          .on(VKID.WidgetEvents.ERROR, (error) => {
            console.error('Ошибка виджета:', error);
          })
          .on(VKID.OneTapInternalEvents.RENDER, () => {
            console.log('Виджет успешно отрендерен');
          })
          .on(VKID.OneTapInternalEvents.LOGIN_SUCCESS, (payload) => {
            
            const jsonData = {
              code: payload.code,
              device_id: payload.device_id,
              state: payload.state,
              ext_id: payload.ext_id || "",
              type: payload.type,
            }
            
            mainApi
            .authVKAction(jsonData)
            .then((response) => {
                  const token = response;
                  localStorage.setItem('token', token);
                  dispatch(loginUserAction({ is_logged: true }));
                  setLoginModal(false);
                  navigate('/');
            })
            .catch((error) => {
              console.error(error);
            });

          });
      }
    };

    script.onerror = () => {
      console.error('Ошибка загрузки VK ID SDK.');
    };

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return <div id="vk-one-tap"></div>;
};

export default VKFloatingLoginComponent;