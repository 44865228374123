const SHOWMODALBONUSCODE = "SHOWMODALBONUSCODE";
const CLEARMODALBONUSCODE = "CLEARMODALBONUSCODE";

const initialState = {
  show: false,
};

const modalBonusCodeReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOWMODALBONUSCODE:
      return {
        ...state,
        show: true
      };
    case CLEARMODALBONUSCODE:
      return {
        ...state,
        show: false
      };
    default:
      return state;
  }
};

export const modalBonusCodeAction = (payload) => {
  return {
    type: SHOWMODALBONUSCODE
  };
};

export const modalBonusCodeClearAction = () => {
  return {
    type: CLEARMODALBONUSCODE
  };
};

export default modalBonusCodeReducer;
