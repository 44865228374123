import React, { useEffect } from "react";

const RefLink = () => {
// Функция для получения значения параметра ref из строки запроса
function getRefValue() {
  const urlParams = new URLSearchParams(window.location.search); // Получаем параметры из строки запроса
  return urlParams.get('ref'); // Возвращаем значение параметра 'ref'
}

// Функция для установки куки
function setCookie(name, value, days) {
  const d = new Date();
  d.setTime(d.getTime() + days * 24 * 60 * 60 * 1000);
  const expires = `expires=${d.toUTCString()}`;
  // document.cookie = name + "=" + value + ";" + expires + ";path=/"; // Сохраняем куки
  document.cookie = `ref=${encodeURIComponent(value)};${expires};path=/; domain=.primoloot.com; Secure; SameSite=None`;
}

// Извлекаем значение ref из строки запроса и сохраняем его в куки
const refValue = getRefValue();
if (refValue) {
  setCookie("ref", refValue, 30); // Сохраняем в куки с именем "ref" на 30 дней
}


};

export default RefLink;
