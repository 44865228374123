import React, { useEffect, useState } from "react";
import { NavLink, Route, Routes, useNavigate } from "react-router-dom";

import Items from "./profile-menu-pages/items/items";
import History from "./profile-menu-pages/history/history";
import Upgrade from "./profile-menu-pages/upgrades/upgrades";
import AffiliateProgram from "./profile-menu-pages/affiliate-program/affiliate-program";
import Settings from "./profile-menu-pages/settings/settings";
import { modalBonusCodeAction } from "../../redux/modal-bonuscode-reducer";


import { useSelector, useDispatch } from "react-redux";
import { formatNumber } from "../../helpers/price";
import { mainApi } from "../../components/utils/main-api";
import { snackbarAction, snackbarClearAction } from "../../redux/snackbar-reducer";

import "./profile-page.css";
import user_avatar from "../../assets/images/profile-avatar.png";
import money from "../../assets/icons/user-money.png";
import { ReactComponent as Wallet } from "../../assets/icons/wallet-icon.svg";
import { ReactComponent as EditIcon } from "../../assets/icons/edit-icon.svg";
import { ReactComponent as CopyIcon } from "../../assets/icons/copy-icon.svg";
import { ReactComponent as ItemsIcon } from "../../assets/icons/profile-icons/items-icon.svg";
import { ReactComponent as HistoryIcon } from "../../assets/icons/profile-icons/history-icon.svg";
import { ReactComponent as UpgradeIcon } from "../../assets/icons/profile-icons/upgrade.svg";
import { ReactComponent as PartnersIcon } from "../../assets/icons/profile-icons/partners-icon.svg";
import { ReactComponent as SettingsIcon } from "../../assets/icons/profile-icons/settings-icon.svg";
import { ReactComponent as LogoutIcon } from "../../assets/icons/profile-icons/logout.svg";
import { ReactComponent as OkeyIcon } from "../../assets/icons/okey-icon.svg";

function ProfilePage( setLoginModal ) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [userData, setUserData] = useState({});
  const usersData = useSelector((state) => state.user.user);
  const [editUID, setEditUID] = useState(false);
  const [valueUID, setValueUID] = useState(usersData?.player_id || "");

  const snackbarActions = (snackText, error) => {
    dispatch(snackbarAction({snackText, error}))
    setTimeout(() => {
      dispatch(snackbarClearAction())
    }, 2000);
  };

  const modalBonusCodeActions = () => {
      dispatch(modalBonusCodeAction())
  };

  const logout = () => {
    localStorage.clear();
    sessionStorage.clear();
    navigate("/");
    window.location.reload();
  };

  const upDateUserUID = (uid) => {
    mainApi
      .upDateUserUID({
        "player_id": uid
      })
      .then((data) => {
        if (data?.player_id) {
          setValueUID(data?.player_id);
          setEditUID(false);
          snackbarActions('UID обновлен');
        }
      })
      .catch((error) => {
        console.log(error);
        snackbarActions("error", error);
      });
  }

  const saveUID = () => {
    mainApi
      .userGenshinUidAction({
        "uid": valueUID
      })
      .then((data) => {
        if (data?.result?.message) {
          snackbarActions(data?.result?.message);
        }
        if(data?.result?.uid){
          upDateUserUID(data?.result?.uid);
        }
      })
      .catch((error) => {
        console.log("error", error);
        snackbarActions("error", error);
      });
  }

  useEffect(() => {
    if (usersData) {
      setUserData(usersData);
      setValueUID(usersData.player_id);
    }
  }, [usersData]);

  useEffect(() => {
    if (!usersData.is_logged) {
      navigate("/");
    }
  }, [navigate, usersData]);

  React.useEffect(() => {
    document.title = `Профиль ${usersData?.user?.username} | Primoloot.com`;
  }, [usersData]);

  return (
    <div className="page_template profile_page">
      <div className="profile_menu">
        <div className="user_profile">
          <div className="user_descriptions">
            <div className="user_avatar">
              <NavLink to="/profile">
                <img
                  src={
                    userData && userData.image
                      ? userData.image
                      : user_avatar
                  }
                  alt="user avatar"
                />
              </NavLink>
            </div>
            <div className="user_descr">
              <p className="user_name">
                {userData?.user?.username || "Загрузка..."}
              </p>
              <div className="user_moneys">
                <img src={money} alt="" />
                <p>{formatNumber(userData.balance)} ₽</p>
              </div>
            </div>
          </div>
          <NavLink to="/deposit">
            <div className="replenish_wallet main_btn_green" title="Пополнение баланса">
              <Wallet />
            </div>
          </NavLink>
        </div>
        <div className="user_id">
          <div className="user_id_input">
            <p>UID</p>
            <input
              type="text"
              value={valueUID || ""}
              className={`uid_input ${editUID ? "editing" : ""}`}
              readOnly={!editUID}
              onChange={(e) => setValueUID(e.target.value)}
              maxLength={9}
            />
          </div>
          <div className="user_id_actions">
            {editUID ? (
              <>
                <OkeyIcon
                  onClick={saveUID} 
                  title="Сохранить UID"
                  className="grey_icon"
                />
             
              </>
            ) : (
              <div
                title="Редактировать UID"
                className="grey_icon"
                onClick={() => setEditUID(true)} 
              >
                <EditIcon />
              </div>
            )}
            <div title="Скопировать UID" className="grey_icon">
              <CopyIcon />
            </div>
          </div>
        </div>

        
        <div className="bonus_activation grey_icon" onClick={() => modalBonusCodeActions()}>
          <p>Активировать бонус код</p>
        </div>

        <div className="profile_menu_list">
          <NavLink to="items">
            <div className="profile_menu_list_item">
              <ItemsIcon />
              <p>Предметы</p>
            </div>
          </NavLink>
          <NavLink to="history">
            <div className="profile_menu_list_item">
              <HistoryIcon />
              <p>История</p>
            </div>
          </NavLink>
          <NavLink to="upgrades">
            <div className="profile_menu_list_item">
              <UpgradeIcon />
              <p>Апгрейды</p>
            </div>
          </NavLink>
          <NavLink to="affiliate-program">
            <div className="profile_menu_list_item">
              <PartnersIcon />
              <p>Партнерская программа</p>
            </div>
          </NavLink>
          <NavLink to="settings">
            <div className="profile_menu_list_item">
              <SettingsIcon />
              <p>Настройки</p>
            </div>
          </NavLink>
          <div className="profile_menu_line"></div>
          <div className="profile_menu_list_item" onClick={logout}>
            <LogoutIcon />
            <p>Выйти из аккаунта</p>
          </div>
        </div>
      </div>
      <div className="profile_content">
        <Routes>
          <Route path="/" element={<Items />} />
          <Route path="/items" element={<Items />} />
          <Route path="/history" element={<History />} />
          <Route path="/upgrades" element={<Upgrade />} />
          <Route path="/affiliate-program" element={<AffiliateProgram />} />
          <Route path="/settings" element={<Settings />} />
        </Routes>
      </div>
    </div>
  );
}

export default ProfilePage;
