import React, { Suspense, useState } from "react";
import { ReactComponent as TitleIcon } from "../../assets/icons/cases-title-icon.svg";
import Card from "../card/card";
import { useEffect } from "react";

function CasesCategory({name, cases}) {

  return (

    <div>
      <div className="cases_block_title">
        <TitleIcon />
        <h1>{name}</h1>
      </div>
      <div className="case_block_content">
        {cases.map((item, i) => (
          <Card key={i} data={item} />
        ))}
      </div>
    </div>

  );
}

export default CasesCategory;
