import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { loginUserAction } from "../../redux/user-reducer";
import { mainApi } from "../utils/main-api";
import GoogleLoginModal from "../auth-socials/google-login/google-login";
import MailRuOAuth from "../auth-socials/mail.ru-login/mail.ru-login";
import TGLogin from "../auth-socials/tg-login/tg-login";
import VKFloatingLoginComponent from "../auth-socials/vk-login/vk-login";
import YandexAuthButton from "../auth-socials/yandex-login/yandex-login";
import { snackbarAction, snackbarClearAction } from "../../redux/snackbar-reducer";

import { ReactComponent as TelegramIcon } from "../../assets/icons/auth-icons/logo-tg.svg";
import { ReactComponent as ExitIcon } from "../../assets/icons/close-icon.svg";
import { ReactComponent as GoogleIcon } from "../../assets/icons/auth-icons/google-icon.svg";
import { ReactComponent as PasswordIcon } from "../../assets/icons/auth-icons/password-eye.svg";
import "./login-auth.css";

function LoginModal({ setLoginModal, setAuthModalType }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [userName, setUserName] = useState("");
  const [userPassword, setPassword] = useState("");

  const [sessionToken, setSessionToken] = useState('');
  const [countReq, setCountReq] = useState(0);


  const [passwordVisible, setPasswordVisible] = useState(false);
  const [vkData, setVkData] = useState({});
  const [yandexData, setYandexData] = useState({});

  const snackbarActions = (snackText, error) => {
    dispatch(snackbarAction({snackText, error}))
    setTimeout(() => {
      dispatch(snackbarClearAction())
    }, 2000);
  };

  const loginUser = async () => {
    if (userName && userPassword) {
      const formData = {
        username: userName,
        password: userPassword,
      };
       mainApi
        .signInAction(formData)
        .then((data) => {
          localStorage.setItem("token", data);
          const user = {
            is_logged: true,
          };
          dispatch(loginUserAction(user));
          setLoginModal(false);
        })
        .catch((error) => {
          snackbarActions("error", {
            error: error
          });
        });
    }
  };

const tgAuth = async () => {
  try {
      const response = await mainApi.generateTgSession();
      console.log('ответ от сервера:', response)

      if (response.auth_token && response.auth_link) {
          window.open(response.auth_link, '_blank');
          console.log('Ссылка тг', response.auth_link)
          setSessionToken(response.auth_token);
          console.log('Токен', response.auth_token)
      } else {
          snackbarActions('Ошибка при генерации токена Telegram.', true);
      }
  } catch (error) {
      console.error('Ошибка при генерации токена Telegram:', error);
      snackbarActions('Ошибка сети. Проверьте подключение.', true);
  }
};


useEffect(() => {
  if (sessionToken) {
      const interval = setInterval(() => {
          mainApi
              .authorizeTgSession({ "auth_token": sessionToken })
              .then((response) => {
                  const token = response;
                  localStorage.setItem('token', token);
                  dispatch(loginUserAction({ is_logged: true }));
                  setLoginModal(false);
                  navigate('/');
                  clearInterval(interval);
              })
              .catch((error) => {
                  console.error('Ошибка при проверке статуса Telegram:', error);
                  // clearInterval(interval);
              });
      }, 3000);

      return () => clearInterval(interval);
  }
}, [sessionToken]);


  const authGoogle = (data) => {
    console.log(data)
    mainApi
      .authGoogleAction(data)
      .then((res) => {
        localStorage.setItem("token", res.access_token);
        const is_logged = {
          is_logged: true,
        };

        dispatch(loginUserAction(is_logged));
        dispatch(loginUserAction(res.user));
        setLoginModal(false);
      })
      .catch((error) => {
        console.log("google error");
      });
  };

  useEffect(() => {
    if (vkData && vkData.id) {
      const user = {
        username: `${vkData.first_name}_${vkData.last_name}`,
        vkontakte_id: vkData.id,
        picture: vkData.avatar,
      };

      mainApi
        .authVKAction()
        .then((userData) => {
          localStorage.setItem("token", userData.access_token);
          const is_logged = {
            is_logged: true,
          };
          dispatch(loginUserAction(is_logged));
          dispatch(loginUserAction(userData.user));
          setLoginModal(false);
        })
        .catch(() => {
          console.log("error");
        });
    }
  }, [dispatch, navigate, setLoginModal, vkData]);

  useEffect(() => {
    if (yandexData && yandexData.id) {
      const user = {
        yandex_id: yandexData.id,
        email: yandexData.default_email,
        picture: `https://avatars.yandex.net/get-yapic/${yandexData.default_avatar_id}/islands-200`,
        username: yandexData.login,
      };
      mainApi
        .authYandexAction(user)
        .then((userData) => {
          localStorage.setItem("token", userData.access_token);
          const user = {
            is_logged: true,
          };
          dispatch(loginUserAction(user));
          dispatch(loginUserAction(userData.user));
          setLoginModal(false);
        })
        .catch((error) => {
          console.log("error");
        });
    }
  }, [dispatch, navigate, setLoginModal, yandexData]);

  return (
    <div className="modal_wrapper_template">
      <div className="modal_template login_modal">
        <div className="modal_header">
          <p className="modal_title">Вход</p>
          <button
            className="modal_closer"
            onClick={() => setLoginModal(false)}
          >
            <ExitIcon />
          </button>
        </div>
        <p className="login_greeting">Добро пожаловать на PrimoLoot</p>
        <div className="auth_wrapper">
          <div className="auth_content">
            <div className="auth_socials">
              {/* <div className="google_auth_btn_wrapper">
                <div className="google_auth_btn">
                  <GoogleIcon
                    className="google_fake"
                  />
                  <GoogleLoginModal authGoogle={authGoogle} />
                </div>
              </div> */}



              <VKFloatingLoginComponent
                setLoginModal={setLoginModal}
              />

              <div className="tg_login_btn" onClick={tgAuth}>
                <TelegramIcon />
                Войти через Telegram
                </div>

            </div>
            

          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginModal;
