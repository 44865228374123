import React from "react";
import { formatNumber } from "../../helpers/price";
import { ReactComponent as Defeat } from "../../assets/icons/defeat.svg";
import { ReactComponent as Arrow } from "../../assets/icons/green-okey-icon.svg";
import { ReactComponent as Balance } from "../../assets/icons/dollar-icon.svg";

const UpgradeCardUser = React.memo(({ item }) => {

  const calculateChance = (betPrice, upgradePrice, coefficient = 1) => {
    if (!betPrice || !upgradePrice) return 0;
    return ((betPrice / upgradePrice) * coefficient) * 100;
  };

  const betPrice = item.upgraded[0]?.price || item.balance || 0;
  const upgradePrice = item.desired[0]?.price || 0; 

  const chance = calculateChance(betPrice, upgradePrice, 0.85);

  return (
    <div className="user_page-upgrade">
      <div className="user_page-upgrade-items">
        <div className="user_page-upgrade-item">
          <span className="user_page-upgrade-item-label">Ставка</span>
          <div className="user_page-upgrade-item-block">
            <div className="user_page-upgrade-price">
              {formatNumber(betPrice)} ₽
            </div>
            <div className="user_page-upgrade-icon">
              {item.upgraded[0]?.image ? (
                <img
                  src={item.upgraded[0]?.image}
                  className="user_page-upgrade-icon-src"
                  alt="Иконка апгрейда"
                />
              ) : (
                <Balance className="user_page-upgrade-icon-src" />
              )}
            </div>
            <div className="user_page-upgrade-name">
              {item.upgraded[0]?.name || "БАЛАНС"}
            </div>
          </div>
        </div>
        <div className="user_page-upgrade-item">
          <span className="user_page-upgrade-item-label">Апгрейд</span>
          <div className="user_page-upgrade-item-block">
            <div className="user_page-upgrade-price">
              {formatNumber(upgradePrice)} ₽
            </div>
            <div className="user_page-upgrade-icon">
              {item.desired[0]?.image ? (
                <img
                  src={item.desired[0]?.image}
                  className="user_page-upgrade-icon-src"
                  alt="Иконка апгрейда"
                />
              ) : (
                <Arrow className="user_page-upgrade-icon-src" />
              )}
            </div>
            <div className="user_page-upgrade-name">
              {item.desired[0]?.name || "Неизвестно"}
            </div>
          </div>
        </div>
      </div>
      <div className="user_page-upgrade-desc">
        <div className="user_page-upgrade-chance">
          <span className="user_page-upgrade-chance_percent">
            {chance ? `${chance.toFixed(1)}%` : "-"}
          </span>
          <span className="user_page-upgrade-chance_name">Шанс</span>
        </div>
        <div className={`user_page-upgrade-status ${item.success ? "status-success" : "status-failure"}`}>
        {item.success ? "Победил" : "Проиграл"}
      </div>
      </div>
    </div>
  );
});

export default UpgradeCardUser;