import React from "react";
import { formatNumber } from "../../helpers/price";
import { ReactComponent as Loading } from "../../assets/icons/loading.svg";


const ItemCardProfile = React.memo(({ items, outputs, saleItems }) => {
  return (
    <div className="profile_items_list_item">
    <img src={items.image} alt="" />
    <div className="profile_items_list_item_desc">
      <p className="profile_items_name">{items.name}</p>
      <div className="profile_item_actions_list">
        
          {items.withdrawal_process ? (
          <div className="profile_item_action least_item">
            <Loading />
            <p>На выводе</p>
          </div>
        ) : (
            <>
              <div className="profile_item_action green_item" onClick={() => outputs(items)}>
                <p>Получить на аккаунт</p>
              </div>
              <div className="profile_item_action purple_item" onClick={() => saleItems(items.id, items.price)}>
                <p>Продать за {formatNumber(items.price)} ₽</p>
              </div>
            </>
          )}
      </div>
    </div>
  </div>
  );
});

export default ItemCardProfile;