import React from "react";
import Loader from "../loader/loader";

const DataList = ({
  items,
  renderItem,
  isLoading,
  isLoadingMore,
  loadMore,
  dataLength,
  additionalClass = "",
}) => (
  <>
    {isLoading ? (
      <Loader />
    ) : (
      <>
        <div className={`user_page_crystals ${additionalClass}`}>
          {items.map((item, index) => renderItem(item, index))}
        </div>
        {dataLength > items.length && (
          <div className="show_more_btn user_show_more_btn">
            <button
              className="grey_btn"
              onClick={loadMore}
              disabled={isLoadingMore}
            >
              {isLoadingMore ? "Загрузка..." : "Показать ещё"}
            </button>
          </div>
        )}
      </>
    )}
  </>
);

export default DataList;
