import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { loginUserAction } from "../../redux/user-reducer";
import { mainApi } from "../../components/utils/main-api";

import { snackbarAction, snackbarClearAction } from "../../redux/snackbar-reducer";
import './adminatlogin-auth.css'
import { ReactComponent as ExitIcon } from "../../assets/icons/close-icon.svg";
import { ReactComponent as GoogleIcon } from "../../assets/icons/auth-icons/google-icon.svg";
import { ReactComponent as PasswordIcon } from "../../assets/icons/auth-icons/password-eye.svg";


function AdminLog({ setLoginModal, setAuthModalType }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [userName, setUserName] = useState("");
  const [userPassword, setPassword] = useState("");

  const [passwordVisible, setPasswordVisible] = useState(false);


  const snackbarActions = (snackText, error) => {
    dispatch(snackbarAction({snackText, error}))
    setTimeout(() => {
      dispatch(snackbarClearAction())
    }, 2000);
  };

  const loginUser = async () => {
    if (userName && userPassword) {
      const formData = {
        username: userName,
        password: userPassword,
      };
       mainApi
        .signInAction(formData)
        .then((data) => {
          localStorage.setItem("token", data);
          const user = {
            is_logged: true,
          };
          dispatch(loginUserAction(user));
          setLoginModal(false);
        })
        .catch((error) => {
          snackbarActions("error", {
            error: error
          });
        });
    }
  };



  return (
    <div className="admin_modal_wrapper_template">
      <div className="admin_modal_template login_modal">
        <div className="admin_modal_header">
          <p className="admin_modal_title">Вход</p>
          <button
            className="admin_modal_closer"
            onClick={() => setLoginModal(false)}
          >
            <ExitIcon />
          </button>
        </div>
        <p className="admin_login_greeting">Добро пожаловать в Primoloot</p>
        <div className="admin_auth_wrapper">
          <div className="admin_auth_content">
            
            <div className="admin_aouth_login_inputs">
              <input
                type="email"
                name="username"
                className="aouth_login_input"
                placeholder="Email / Логин"
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
              />
              <div className="admin_auth_password_input">
                <input
                  type={passwordVisible ? "text" : "password"}
                  className="aouth_login_input"
                  placeholder="Пароль"
                  name="password"
                  value={userPassword}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <PasswordIcon
                  onClick={() => setPasswordVisible(!passwordVisible)}
                  className={
                    passwordVisible
                      ? "hide_password active_auth_password"
                      : "hide_password"
                  }
                />
              </div>
            </div>
          </div>
          <div className="password_recovery_btn">
            <p>Забыли пароль?</p>
          </div>
          <button className="submit_btn" onClick={loginUser}>
            ВОЙТИ
          </button>
          <div className="auth_types">
            <p>
              Еще нет аккаунта?{" "}
              <span onClick={() => setAuthModalType(false)}>
                Зарегистрируйтесь
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminLog;
