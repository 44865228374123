import React, { useEffect } from "react";

import "../agreement/agreement.css"

const Privacy = () => {
  useEffect(() => {
    document.title = "Политика в отношении обработки персональных данных";
  }, []);

  return (
    <div className="agreement-page">
        <h1 className="agreement-h1">Политика в отношении обработки персональных данных</h1>
<p className="agreement-label">1.	Общие положения</p>
<p>Настоящая политика обработки персональных данных разработана в соответствии с требованиями Федерального закона от 27.07.2006 № 152-ФЗ «О персональных данных» (далее – Закон о персональных данных) и описывает процесс обработки персональных данных и меры, направленные на обеспечение их безопасности, которые принимает PrimoLoot (далее – Оператор).</p>
<p>1.1. Оператор ставит основной задачей и обязательным условием своей деятельности соблюдение прав и свобод человека и гражданина в процессе обработки его персональных данных, включая защиту права на неприкосновенность частной жизни, личную и семейную тайну.</p>
<p>1.2. Настоящая политика обработки персональных данных (далее – Политика) применяется ко всей информации, которую Оператор может собрать о пользователях веб-сайта https://primoloot.com.</p>
<p className="agreement-label">2.	Основные термины, используемые в Политике</p>
<p>2.1. Автоматизированная обработка персональных данных – обработка персональных данных с использованием средств вычислительной техники.</p>
<p>2.2. Блокировка персональных данных – временная приостановка обработки персональных данных (за исключением случаев, когда обработка необходима для уточнения этих данных).</p>
<p>2.3. Веб-сайт – совокупность графических, информационных материалов, а также программного обеспечения и баз данных, обеспечивающих доступность этих данных в сети Интернет по адресу https://primoloot.com.</p>
<p>2.4. Информационная система персональных данных — совокупность персональных данных, хранящихся в базах данных, и технологии, обеспечивающей их обработку.</p>
<p>2.5. Обезличивание персональных данных — действия, в результате которых невозможно определить, к какому конкретному Пользователю или субъекту персональных данных они принадлежат без использования дополнительной информации.</p>
<p>2.6. Обработка персональных данных – любые действия (операции) или их совокупность, выполняемые с персональными данными с использованием или без использования автоматизированных средств, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление или уничтожение персональных данных.</p>
<p>2.7. Оператор – юридическое или физическое лицо, орган государственной или муниципальной власти, которое самостоятельно или совместно с другими лицами организует и (или) осуществляет обработку персональных данных, а также определяет цели и состав данных, которые подлежат обработке, а также действия, выполняемые с персональными данными.</p>
<p>2.8. Персональные данные – любая информация, относящаяся прямо или косвенно к определенному или определяемому Пользователю веб-сайта https://primoloot.com.</p>
<p>2.9. Персональные данные, разрешенные субъектом персональных данных для распространения — это данные, доступ к которым предоставлен субъектом персональных данных неограниченному кругу лиц посредством согласия на их обработку и распространение в соответствии с законодательством о персональных данных (далее – персональные данные, разрешенные для распространения).</p>
<p>2.10. Пользователь – любой посетитель веб-сайта https://primoloot.com.</p>
<p>2.11. Предоставление персональных данных – действия, направленные на раскрытие персональных данных определенному лицу или группе лиц.</p>
<p>2.12. Распространение персональных данных – действия, направленные на раскрытие персональных данных неопределенному кругу лиц (включая передачу данных) или на ознакомление с ними широкой аудиторией, в том числе путем публикации в средствах массовой информации, размещения в сети Интернет или предоставления доступа иным способом.</p>
<p>2.13. Трансграничная передача персональных данных – передача персональных данных на территорию другого государства органу власти, физическому или юридическому лицу, находящемуся за пределами России.</p>
<p>2.14. Уничтожение персональных данных – действия, при которых персональные данные уничтожаются безвозвратно, с невозможностью восстановления их содержания в</p>
<p className="agreement-label">3.	Основные права и обязанности Оператора</p>
<p>3.1. Оператор имеет право:</p>
<p>– получать от субъекта персональных данных достоверную информацию и/или документы, содержащие персональные данные;</p>
<p>– в случае, если субъект персональных данных отзывает свое согласие на обработку персональных данных, продолжить обработку данных без согласия субъекта, если это обосновано положениями Закона о персональных данных;</p>
<p>– самостоятельно определять необходимые меры и их перечень для выполнения обязательств, установленных Законом о персональных данных и соответствующими нормативными актами, если иное не предусмотрено данным законом или другими федеральными законами.</p>
<p>3.2. Оператор обязан:</p>
<p>– по запросу субъекта персональных данных предоставлять информацию о процессе обработки его персональных данных;</p>
<p>– организовывать обработку персональных данных в соответствии с действующим законодательством РФ;</p>
<p>– отвечать на обращения и запросы субъектов персональных данных и их законных представителей в порядке, установленном Законом о персональных данных;</p>
<p>– предоставлять необходимую информацию в уполномоченный орган по защите прав субъектов персональных данных в течение 30 дней с момента получения запроса;</p>
<p>– публиковать или обеспечивать доступ к настоящей Политике обработки персональных данных для всеобщего ознакомления;</p>
<p>– принимать правовые, организационные и технические меры для защиты персональных данных от неправомерного или случайного доступа, уничтожения, изменения, блокировки, копирования, предоставления, распространения, а также от других неправомерных действий с персональными данными;</p>
<p>– прекратить передачу, распространение, предоставление доступа к персональным данным, прекратить обработку и уничтожить персональные данные в порядке, предусмотренном Законом о персональных данных;</p>
<p>– выполнять другие обязательства, установленные Законом о персональных данных.</p>
<p className="agreement-label">4.	Основные права и обязанности субъектов персональных данных</p>
<p>4.1. Субъекты персональных данных имеют право:</p>
<p>– получать информацию о том, как обрабатываются их персональные данные, за исключением случаев, предусмотренных федеральными законами. Информация должна быть предоставлена в доступной форме и не содержать персональных данных других субъектов, если только нет законных оснований для их раскрытия. Перечень информации и порядок ее получения регулируется Законом о персональных данных;</p>
<p>– требовать от Оператора уточнения, блокирования или уничтожения своих персональных данных, если они являются неполными, устаревшими, неточными, незаконно полученными или не нужными для заявленной цели обработки, а также принимать меры для защиты своих прав, предусмотренные законом;</p>
<p>– требовать предварительного согласия при обработке персональных данных с целью продвижения товаров, работ и услуг;</p>
<p>– отозвать согласие на обработку персональных данных;</p>
<p>– обжаловать неправомерные действия или бездействие Оператора при обработке своих персональных данных в уполномоченный орган по защите прав субъектов персональных данных или в суд;</p>
<p>– пользоваться другими правами, предусмотренными законодательством РФ.</p>
<p>4.2. Субъекты персональных данных обязаны:</p>
<p>– предоставлять Оператору достоверную информацию о себе;</p>
<p>– информировать Оператора об изменениях (уточнениях, обновлениях) своих персональных данных.</p>
<p>4.3. Лица, которые предоставили Оператору недостоверную информацию о себе или о другом субъекте персональных данных без его согласия, несут ответственность в соответствии с законодательством РФ.</p>
<p className="agreement-label">5.	Оператор может обрабатывать следующие персональные данные Пользователя</p>
<p>5.1. Фамилия, имя, отчество.</p>
<p>5.2. Электронный адрес.</p>
<p>5.3. Год, месяц, дата и место рождения.</p>
<p>5.4. Фотографии.</p>
<p>5.5. Токен авторизации через ВКонтакте.</p>
<p>5.6. Токен авторизации через Телеграм.</p>
<p>5.7. Также на сайте осуществляется сбор и обработка обезличенных данных о посетителях (включая файлы «cookie») с помощью инструментов интернет-анализа (например, Яндекс Метрика, Google Analytics и других).</p>
<p>5.8. Все вышеперечисленные данные далее в Политике будут упомянуты как «Персональные данные».</p>
<p>5.9. Оператор не осуществляет обработку персональных данных, относящихся к специальным категориям, таким как расовая или национальная принадлежность, политические взгляды, религиозные или философские убеждения, интимная жизнь.</p>
<p>5.10. Обработка персональных данных, которые разрешены для распространения и относятся к специальным категориям, указанных в ч. 1 ст. 10 Закона о персональных данных, разрешается только при соблюдении условий, предусмотренных ст. 10.1 этого Закона.</p>
<p>5.11. Согласие Пользователя на обработку персональных данных, разрешенных для распространения, должно быть оформлено отдельно от других согласий на обработку его персональных данных. В таком случае соблюдаются условия, указанные в ст. 10.1 Закона о персональных данных. Требования к содержанию такого согласия определяет уполномоченный орган по защите прав субъектов персональных данных.</p>
<p>5.11.1. Согласие на обработку персональных данных, разрешенных для распространения, Пользователь предоставляет Оператору напрямую.</p>
<p>5.11.2. Оператор обязан в течение не более трех рабочих дней с момента получения указанного согласия Пользователя опубликовать информацию об условиях обработки таких данных, а также о наличии запретов и ограничений на их обработку для неограниченного круга лиц.</p>
<p>5.11.3. Передача (распространение, предоставление, доступ) персональных данных, разрешенных для распространения, должна быть прекращена в любой момент по требованию субъекта персональных данных. Это требование должно содержать фамилию, имя, отчество (если имеется), контактные данные (номер телефона, адрес электронной почты или почтовый адрес), а также список персональных данных, обработку которых необходимо прекратить. Персональные данные, указанные в этом требовании, могут обрабатываться только Оператором, которому оно направлено.</p>
<p>5.11.4. Согласие на обработку персональных данных, разрешенных для распространения, утрачивает силу с момента получения Оператором требования, указанного в п. 5.11.3 настоящей Политики относительно прекращения их обработки.</p>
<p className="agreement-label">6. Принципы обработки персональных данных</p>
<p>6.1. Обработка персональных данных проводится на законных и справедливых основаниях.</p>
<p>6.2. Обработка данных ограничена достижением конкретных, заранее определенных и законных целей. Обработка, не соответствующая целям сбора данных, не допускается.</p>
<p>6.3. Нельзя объединять базы данных, содержащие персональные данные, если их обработка направлена на цели, которые не совместимы между собой.</p>
<p>6.4. Обрабатываются только те персональные данные, которые необходимы для достижения целей их обработки.</p>
<p>6.5. Объем и содержание обрабатываемых данных соответствуют заявленным целям. Не допускается обработка избыточных данных относительно заявленных целей.</p>
<p>6.6. Обработка данных обеспечивает их точность, достаточность и актуальность для целей обработки. Оператор обязан принимать меры по удалению или уточнению неполных или неточных данных, если это необходимо.</p>
<p>6.7. Персональные данные хранятся в такой форме, которая позволяет идентифицировать субъектов данных, не дольше, чем это требуется для целей обработки. Если срок хранения не установлен законом или договором, данные уничтожаются или обезличиваются по достижении целей обработки или утрате необходимости в их дальнейшем использовании.</p>
<p className="agreement-label">7.	Цели обработки персональных данных</p>
<p>7.1. Цели обработки персональных данных Пользователя:</p>
<p>– информирование Пользователя посредством отправки электронных писем;</p>
<p>– заключение, исполнение и прекращение гражданско-правовых договоров;</p>
<p>– предоставление Пользователю доступа к сервисам, информации и материалам на веб-сайте https://primoloot.com.</p>
<p>7.2. Оператор также вправе направлять Пользователю уведомления о новых продуктах и услугах, специальных предложениях и различных событиях. Пользователь всегда может отказаться от получения таких уведомлений, направив письмо на электронную почту primoloot@proton.me с пометкой «Отказ от уведомлений о новых продуктах и услугах и специальных предложениях».</p>
<p>7.3. Обезличенные данные Пользователей, собранные с помощью сервисов интернет-анализа, используются для изучения действий Пользователей на сайте и улучшения качества сайта и его содержания.</p>
<p className="agreement-label">8. Правовые основания для обработки персональных данных</p>
<p>8.1. Основные правовые основания для обработки персональных данных Оператором включают:</p>
<p>– нормативно-правовые акты, регулирующие деятельность Оператора, например, если деятельность связана с информационными технологиями, созданием сайтов, то можно указать Федеральный закон "Об информации, информационных технологиях и о защите информации" от 27.07.2006 N 149-ФЗ;</p>
<p>– уставные документы Оператора;</p>
<p>– договоры, заключаемые между Оператором и субъектом персональных данных;</p>
<p>– федеральные законы и другие нормативно-правовые акты в области защиты персональных данных;</p>
<p>– согласие Пользователей на обработку их персональных данных, а также на обработку персональных данных, разрешённых для распространения.</p>
<p>8.2. Оператор обрабатывает персональные данные Пользователя только в случае их заполнения и/или отправки Пользователем через специальные формы на сайте https://primoloot.com или отправленные Оператору по электронной почте. Заполняя эти формы и/или отправляя свои персональные данные, Пользователь соглашается с настоящей Политикой.</p>
<p>8.3. Оператор также обрабатывает обезличенные данные о Пользователе в случае, если это разрешено настройками браузера Пользователя (например, если включены файлы «cookie» и технология JavaScript).</p>
<p></p>
<p>8.4. Субъект персональных данных самостоятельно решает, предоставлять ли свои персональные данные, и дает согласие на их обработку свободно, по собственной воле и в своих интересах.</p>
<p className="agreement-label">9. Условия обработки персональных данных</p>
<p>9.1. Обработка персональных данных осуществляется с согласия субъекта на обработку его персональных данных.</p>
<p>9.2. Обработка данных необходима для достижения целей, предусмотренных международными договорами Российской Федерации или законом, а также для выполнения функций, полномочий и обязанностей, возложенных на Оператора законодательством Российской Федерации.</p>
<p>9.3. Обработка персональных данных требуется для осуществления правосудия, исполнения судебного акта, актов других органов или должностных лиц, подлежащих исполнению согласно законодательству Российской Федерации об исполнительном производстве.</p>
<p>9.4. Обработка данных необходима для исполнения договора, стороной которого является субъект персональных данных, или для заключения договора по инициативе субъекта или договора, по которому субъект будет являться выгодоприобретателем или поручителем.</p>
<p>9.5. Обработка данных необходима для осуществления прав и законных интересов Оператора или третьих лиц, а также для достижения общественно значимых целей, при этом не нарушая прав и свобод субъекта данных.</p>
<p>9.6. Осуществляется обработка общедоступных персональных данных, доступ к которым предоставлен субъектом данных или по его запросу.</p>
<p>9.7. Обработка персональных данных, которые подлежат публикации или обязательному раскрытию в соответствии с федеральным законодательством, также осуществляется.</p>
<p className="agreement-label">10. Порядок сбора, хранения, передачи и других видов обработки персональных данных</p>
<p>Безопасность персональных данных, обрабатываемых Оператором, обеспечивается через принятие правовых, организационных и технических мер, которые соответствуют требованиям законодательства в области защиты персональных данных.</p>
<p>10.1. Оператор обеспечивает сохранность персональных данных и предпринимает все необходимые меры для предотвращения доступа неуполномоченных лиц к этим данным.</p>
<p>10.2. Персональные данные Пользователя не передаются третьим лицам, за исключением случаев, предусмотренных действующим законодательством или с согласия субъекта данных на передачу данных третьим лицам для исполнения обязательств по гражданско-правовому договору.</p>
<p>10.3. В случае выявления неточностей в персональных данных, Пользователь может их актуализировать, направив соответствующее уведомление через чат или на электронный адрес Оператора primoloot@proton.me с пометкой «Актуализация персональных данных».</p>
<p>10.4. Срок обработки персональных данных определяется достижением целей их сбора, если иное не предусмотрено договором или законодательством. Пользователь может в любой момент отозвать свое согласие на обработку персональных данных, направив уведомление через чат или на электронную почту primoloot@proton.me с пометкой «Отзыв согласия на обработку персональных данных».</p>
<p>10.5. Вся информация, собираемая сторонними сервисами, такими как платежные системы, средства связи и другие поставщики услуг, хранится и обрабатывается в соответствии с их Пользовательским соглашением и Политикой конфиденциальности. Оператор не несет ответственности за действия этих сторонних организаций. Пользователь обязан самостоятельно ознакомиться с этими документами.</p>
<p>10.6. Запреты на передачу или обработку персональных данных, установленные субъектом данных, не действуют, если обработка необходима в государственных, общественных или публичных интересах в соответствии с законодательством РФ.</p>
<p>10.7. Оператор обеспечивает конфиденциальность персональных данных при их обработке.</p>
<p>10.8. Оператор хранит персональные данные в форме, позволяющей определить субъекта данных, не дольше, чем этого требуют цели обработки, если иное не установлено федеральным законом или договором.</p>
<p>10.9. Обработка персональных данных прекращается при достижении целей обработки, истечении срока действия согласия или его отзыве, а также в случае выявления неправомерной обработки данных.</p>
<p className="agreement-label">11. Перечень действий, производимых Оператором с полученными персональными данными</p>
<p>11.1. Оператор выполняет следующие действия с персональными данными: сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление и уничтожение.</p>
<p>11.2. Оператор осуществляет автоматизированную обработку персональных данных, включая передачу полученной информации по информационно-телекоммуникационным сетям или без таковой.</p>
<p className="agreement-label">12. Трансграничная передача персональных данных</p>
<p>12.1. Оператор обязуется убедиться, что страна, в которую предполагается передача персональных данных, предоставляет надежную защиту прав субъектов данных.</p>
<p>12.2. Трансграничная передача персональных данных в страны, которые не соответствуют этим требованиям, может быть осуществлена только с письменного согласия субъекта персональных данных на такую передачу и/или в рамках исполнения договора, стороной которого является субъект данных.</p>
<p className="agreement-label">13. Конфиденциальность персональных данных</p>
<p>Оператор и другие лица, получившие доступ к персональным данным, обязаны не раскрывать и не распространять эти данные без согласия субъекта, если иное не предусмотрено федеральным законодательством.</p>
<p className="agreement-label">14. Заключительные положения</p>
<p>14.1. Пользователь может получить разъяснения по вопросам, связанным с обработкой его персональных данных, обратившись к Оператору через чат или на электронную почту primoloot@proton.me.</p>
<p>14.2. Все изменения в Политике обработки персональных данных будут отражены в этом документе. Политика действует бессрочно до замены ее новой версией.</p>
<p>14.3. Актуальная версия Политики доступна в Интернете по адресу: https://primoloot.com/privacy/.</p>
    </div>
  );
};

export default Privacy;
