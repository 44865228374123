import React, { Suspense } from "react";
import { useEffect, useState } from "react";

import { mainApi } from "../../../../components/utils/main-api";

import "./history.css";
import best_case from "../../../../assets/images/best-case.png";
import { ReactComponent as Status } from "../../../../assets/icons/history-status.svg";
import { formatNumber } from "../../../../helpers/price";
import Preloader from "../../../../components/preloader/preloader";
import Pagination from "../../../../components/pagionation/pagination";
import PaginationLimit from "../../../../components/PaginationLimit/paginationLimit";
import Loader from "../../../../components/loader/loader";

function History() {
  const [itemsHistory, setItemsHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(true); 
  const [currentPage, setCurrentPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [dataLength, setDataLength] = useState(0);

  const getUserItemsHistory = () => {
    const offset = currentPage * limit;
    console.log("Запрос:", { offset, limit });
    mainApi
      .userItemsHistory(limit, offset)
      .then((data) => {
        setItemsHistory(data.results);
        setDataLength(data.count);
      })
      .catch((error) => {
        console.log("error: ", error);
      })
      .finally(() => {
        setIsLoading(false); 
      });
  };

  useEffect(() => {
    getUserItemsHistory();
  }, [currentPage, limit]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="profile_content_wrapper history_content">
      <div className="history_list">
        {itemsHistory.map((items) => (
          <div className="history_item" key={items.id}>
            <div className="history_item_img">
              <img src={items.image} alt="" />
            </div>
            <div className="history_item_descriptions df_aic_jcsb">
              <div>
                <p className="ticket_number">{items.name}</p>
                <p className="history_item_descr">
                  {items.action_date} - {items.name}
                </p>
              </div>
              <div>
                <p className="history_price">{formatNumber(items.price)} ₽</p>
                <p className={items.status === "Продан" ? 'history_status status2' : items.status === "Выведено" ?  'history_status status1' : items.status === "Ошибка вывода" ?  'history_status status3' : 'history_status status4'}>
                  {items.status} <Status />
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="pagination_container">
          <div className="cases_paginations">
            <Pagination
              pageCount={Math.ceil(dataLength / limit)}
              onPageChange={setCurrentPage}
              currentPage={currentPage}
            />
          </div>
          <div className="pagination_limit">
            <PaginationLimit
              limit={limit}
              setLimit={setLimit}
              onPageChange={setCurrentPage}
            />
          </div>
        </div>


    </div>
  
  );
}

export default History;
