
import React, { useState, useEffect, Suspense, lazy } from "react";
import { mainApi } from "../utils/main-api";
import "./cases-characters.css";
import CasesCategory from "../cases-category/cases-category";

function CasesCharacters() {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    mainApi
      .getCasesAll()
      .then((data) => {
        setCategories(data.results);
      })
      .catch((error) => {
        console.log("error: ", error);
      })
  }, []);
  
  return (

    <div className="cases_block cases_characters">
      <div className="cases_block_characters">
        {categories.map((category) => (
          <CasesCategory
            key={category.category_id}
            name={category.name}
            cases={category.cases}
          />
        ))}
      </div>
    </div>

  );
}

export default CasesCharacters;