import React, { useState, useEffect } from "react";
import "./faq.css";

const Faq = () => {
  const [activeCategory, setActiveCategory] = useState("Пополнение"); // Текущая активная категория
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600); // Флаг для мобильного экрана
  const [openQuestionIndex, setOpenQuestionIndex] = useState(null); // Индекс открытого вопроса

  React.useEffect(() => {
     document.title = `Часто задаваемые вопросы - PrimoLoot.com`;
  }, []);



  const faqData = {
    "Пополнение": [
      { question: "Как пополнить баланс?", answer: '1. Нажмите кнопку пополнения баланса 2. Укажите промокод (не обязательно) 3. Выберите удобный для вас метод оплаты 4. Введите сумму пополнения и нажмите "пополнить"' },
      { question: "Что делать если не пришли средства?", answer: "Средства могут прийти не сразу. Подождите несколько минут. Если средства не приходят напишите в чат поддержки, указав все данные платежа и свой ID на сайте." },
    ],
    "Бонусы": [
      { question: "Как использовать промокод?", answer: "Перейдите в раздел активации промокода, введите туда свой промокод, нажмите 'Активировать'" },
      { question: "Где получить промокод?", answer: "Промокоды можете получить в нашем телеграм канале или других социальных сетях." },
      { question: "Какие бывают типы промокодов?", answer: "Есть 2 типа промокодов: 1 тип дает вам сразу сумму на ваш баланс. 2 тип дает вам бонус к пополнению." },
    ],
    "Апгрейды": [
      { question: "Апгрейд за предметы", answer: "1. Выбери предмет из своего инвентаря на сайте 2. Выбери предмет, который хочешь получить в апгрейде 3. Оцени шанс на успех и начни апгрейд 4. Апгрейд считается успешным, если стрелка остановится на зеленой рамке круга  5. В случае недаучи твой предмет будет уничтожен" },
      { question: "Апгрейд за баланс", answer: "1. Выбери 'баланс' на вкладке апгрейдов 2. Введите сумму, которую хочешь внести в апгрейд 3. Выбери предмет, который хочешь получить 4. Оцени шанс на успех и начни апгрейд 5. Апгрейд считается успешным, если стрелка остановится на зеленой рамке круга 6. В случае недаучи твой предмет будет уничтожен" },
    ],
    "Предметы": [
      { question: "Как вывести предмет?", answer: "1. Введите свой UID в профиле, и убедитесь, что он введен верно.(если вы введете UID не верно и отправите заявку на вывод - ваш предмет может уйти не на ваш аккаунт.) 2. Перейдите на вкладку 'Предметы' в вашем профиле и нажмите кнопку 'Получить на аккаунт' 3. Ожидайте получения предметов на свой аккаунт в игре." },
      { question: "Как продать предмет?", answer: "Перейдите на вкладку 'Предметы' в вашем профиле и нажмите кнопку 'Продать'. Если хотите вы можете продать все предметы, которые у вас есть. Для этого нажмите кнопку 'Продать всё'." },
      { question: "Что будет после продажи предмета", answer: "После продажи предмета на ваш баланс на сайте поступит сумма, за которую вы продали предмет" },
    ],
    "Раздачи": [
      { question: "Как участвовать в раздаче?", answer: 'Перейдите на вкладу "Раздачи" и нажмите участвовать.' },
      { question: "Почему я не могу принять участие в раздаче?", answer: "У некоторых раздач есть условия для участия, например пополнить баланс. Выполните условие раздачи и тогда вы сможете принять участие." }
    ],
    "Партнерская программа": [
      { question: "Что такое партнерская программа?", answer: "Партнерская программа дает вам возможность заработать, приглашая своих друзей или других людей на наш сайт. Вы будете получать процент от всех пополнений человека, который зарегистрируется по вашей ссылке, и выводить эти деньги на свои реквизиты." },
      { question: "Как изменить реферальную ссылку?", answer: 'Зайдите в раздел "Партнерская программа" в своем профиле и там вы увидите свою ссылку.' },
      { question: "Куда можно вывести деньги с партнерской программы?", answer: 'Вы можете вывести деньги по СБП, по номеру банковской карты и на криптокошелек.' },
      { question: "Как долго приходят деньги с партнерской программы?", answer: 'Деньги придут на ваш кошелек в течении 24 часов. Если случится задержка, напишите в чат поддержки и укажите ID вашего профиля' },
    ],
  };

  // Слушатель изменения ширины экрана
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 600);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Логика переключения активной категории
  const selectCategory = (category) => {
    setActiveCategory(category); // Устанавливаем активную категорию
    setOpenQuestionIndex(null); // Сбрасываем открытый вопрос
  };

  // Логика открытия/закрытия вопроса
  const toggleQuestion = (index) => {
    setOpenQuestionIndex(openQuestionIndex === index ? null : index);
  };

  return (
    <div className="faq-container">
      <div className="faq">
        {/* Мобильная версия */}
        {isMobile ? (
          <div className="faq-categories">
            {Object.keys(faqData).map((category) => (
              <div
                key={category}
                className={`faq-category ${category === activeCategory ? "active" : ""}`}
                onClick={() => selectCategory(category)}
              >
                <p className={`categoryName ${category === activeCategory ? "active" : ""}`}>{category}</p>
              </div>
            ))}
          </div>
        ) : (
          // Десктопная версия
          <>
            <div className="faq-categories">
              {Object.keys(faqData).map((category) => (
                <div
                  key={category}
                  className={`faq-category ${category === activeCategory ? "active" : ""}`}
                  onClick={() => selectCategory(category)}
                >
                  <p className={`categoryName ${category === activeCategory ? "active" : ""}`}>{category}</p>
                </div>
              ))}
            </div>
          </>
        )}

        <div className="faq-questions">
          {faqData[activeCategory].map((item, index) => (
            <div key={index} className="faq-item">
              <div
                className="faq-question"
                onClick={() => toggleQuestion(index)} // Открываем/закрываем вопрос
              >
                <span>{openQuestionIndex === index ? "-" : "+"}</span> {item.question}
              </div>
              {/* Ответ отображается только для открытого вопроса */}
              {openQuestionIndex === index && (
                <div className="faq-answer">{item.answer}</div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Faq;
