import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import useWebSocket from 'react-use-websocket';

import crystal1 from "../../assets/icons/crystal1.png";
import crystal2 from "../../assets/icons/crystal2.png";
import crystal3 from "../../assets/icons/crystal3.png";
import userImg from "../../assets/images/live-profile-photo.png";
import user_avatar from "../../assets/images/profile-avatar.png";

import "./live-drop-item.css";
function convertToRgba(hexString, alpha) {
  // Проверяем, что переданный цвет имеет правильный формат #RRGGBB
  const hex = hexString.replace('#', '');
  if (hex.length !== 6) {
    console.error('Invalid hex color format:', hexString);
    return '';
  }

  // Разделяем шестнадцатеричный цвет на компоненты R, G, B
  const r = parseInt(hex.slice(0, 2), 16);
  const g = parseInt(hex.slice(2, 4), 16);
  const b = parseInt(hex.slice(4, 6), 16);

  // Возвращаем rgba строку с заданной прозрачностью
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}


function LiveDropItem() {
  const [items, setItems] = useState([]);
  const { sendMessage, lastMessage } = useWebSocket('wss://server.primoloot.com/ws/live');

  useEffect(() => {
    sendMessage(JSON.stringify({ action: 'get_items' }));

    const itemsInterval = setInterval(() => {
      sendMessage(JSON.stringify({ action: 'get_items' }));
    }, 5000);

    return () => {
      clearInterval(itemsInterval);
    };
  }, []);

  useEffect(() => {
    if (lastMessage) {
      const data = JSON.parse(lastMessage.data)
      data.forEach(element => {
        if (!items.some(item => item.user_item_id === element.user_item_id)) {
          setItems(prev => [...prev, element]);
        }
      });
    }
  }, [lastMessage]);
  
  return (
    <div className="live_drop_items_list">
      {items && items.map((items) => (
        // <div key={items.user_item_id} className="live_drop_item" style={{backgroundColor: `${items.rarity_category?.rarity_color}`}}>

<div
  key={items.user_item_id}
  className="live_drop_item"
  style={{
    background: `linear-gradient(
      180deg, 
      ${convertToRgba(items.rarity_category?.rarity_color, 0.9)} 0%, 
      ${convertToRgba(items.rarity_category?.rarity_color, 0.72)} 39.58%, 
      ${convertToRgba(items.rarity_category?.rarity_color, 0.378)} 100%
    )`,
  }}
>

          <div className="live_drop_item_content">
            <div className="live_drop_icon">
              <img src={`${items?.image}`} alt="" className="get_items_image" />
            </div>
            <div className="live_drop_item_descr">
              <p>{items.name}</p>
            </div>
          </div>
          <NavLink to={`/user/${items.user.id}`}>
            <div className="live_drop_item_user">
              <div className="live_drop_icon">
                <img src={items?.user?.image || user_avatar} alt='' className="get_items_user_image" />
              </div>
              <div className="live_drop_item_descr">
               <p>{items.user.firstName && items.user.lastName ? `${items.user.firstName} ${items.user.lastName}` : `${items.user.username}`}</p> 
              </div>
            </div>
          </NavLink>
        </div>
      ))}
    </div>
  );
}

export default LiveDropItem;
