import { useEffect, useRef, useState } from "react";
import { formatNumber } from "../../helpers/price";
import useResizeObserver from "use-resize-observer";

function CaseOpeningMultiple({
  selectedId,
  extendedItems,
  setIsSpinning,
  setWinnedPrizeBlock,
  setWinnedPrize,
  showAnimation,
  caseItems,
  isSpinning,
  playWinnedSound
}) {
  const wheelRef = useRef(null);
  const { ref, height } = useResizeObserver();

  const restartRoulette = () => {
    resetWheel();
    spinWheel();
  }
  useEffect(() => {
    const handleResize = () => {
      if (isSpinning) {
        restartRoulette();
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
    
  }, [isSpinning, height]);

  const resetWheel = () => {
    if (wheelRef.current) {
      wheelRef.current.style.transition = "none";
      wheelRef.current.style.transform = "translateY(0)";

      // eslint-disable-next-line no-unused-expressions
      wheelRef.current.offsetHeight;

      wheelRef.current.addEventListener(
        "transitionend",
        () => {
          setIsSpinning(false);
        },
        { once: true }
      );
    }
  };

  const spinWheel = () => {
    const extendedIndex = extendedItems.findIndex(
      (item) => item.item_id === selectedId.item_id
    );

    if (extendedIndex === -1) {
      return;
    }

    setWinnedPrizeBlock(false);
    setIsSpinning(true);
    setWinnedPrize(
      extendedItems.filter((item) => item.item_id === selectedId.item_id)[0]
    );
    const wrapper = wheelRef.current;
    if (!wrapper) {
      return;
    }

    const viewportCenter = wrapper.offsetHeight / 2;
    const centeringOffset = height / 2;
    const selectedItem =
      wheelRef.current?.children[extendedIndex + caseItems.length * 2];
    
    const selectedItemCenterPosition =
      selectedItem?.offsetTop + height / 2;
    
    const correction = viewportCenter - selectedItemCenterPosition;
    const min = Math.ceil(correction - centeringOffset);
    const max = Math.floor(correction + centeringOffset);
    let spinDistance
    if (Math.random() > 0.5) {
      spinDistance = Math.floor(Math.random() * ((min + height/6)  - min + 1)) + (min + height/6)
    } else {
      spinDistance = Math.floor(Math.random() * (max - (max - height/6) + 1)) + (max - height/6)
    }

    if (showAnimation) {
      wheelRef.current.style.transition =
        "transform 10s cubic-bezier(0.15, 1, 0.40, 1)";
      wheelRef.current.style.transform = `translateY(${spinDistance}px)`;
      
      setTimeout(() => {
        setIsSpinning(false);
        wheelRef.current.style.transition = "transform 0.5s ease-out";
        wheelRef.current.style.transform = `translateY(${correction}px)`;
        setWinnedPrizeBlock(true);
        playWinnedSound();
      }, 9000);

    } else {
      wheelRef.current.style.transition =
        "transform 1s cubic-bezier(0.15, 1, 0.40, 1)";
      wheelRef.current.style.transform = `translateY(${spinDistance}px)`;
      
      setTimeout(() => {
        setIsSpinning(false);
        if (wheelRef.current) {
          wheelRef.current.style.transition = "transform 0.5s ease-out";
          wheelRef.current.style.transform = `translateY(${correction}px)`;
        }
        setWinnedPrizeBlock(true);
        playWinnedSound();
      }, 2000);
    }
  };

  useEffect(() => {
    restartRoulette();
  }, [height]);

  return (
    <div className={`case_opening_multiple_items`} ref={wheelRef}>
      {extendedItems && extendedItems.map((item, index) => (
        <div
          key={index}
          className={
            item.id % 2 == 0
              ? `case_opening_item multiple case_opening_item_bg_firstly ${index}`
              : `case_opening_item multiple case_opening_item_bg_secondary ${index}`
          }
          ref={ref}
        >
          <div className="case_opening_item_img">
            <img
              src={item.image}
              alt=""
            />
          </div>
          <div className="case_opening_item_description">
            <p>{item.name}</p>
          </div>

        </div>
      ))}
    </div>
  )
}

export default CaseOpeningMultiple;
