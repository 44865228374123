import { useDispatch } from "react-redux";
import Select from "react-select";
import { useState } from "react";

import { modalWithdrawClearAction } from "../../redux/modal-withdraw-reducer";
import { formatNumber } from "../../helpers/price";

import { ReactComponent as ExitIcon } from "../../assets/icons/close-icon.svg";
import "./modal-withdraw.css";
import { mainApi } from "../utils/main-api";
import { snackbarAction, snackbarClearAction } from "../../redux/snackbar-reducer";

const option = [
  {
    value: "card",
    label: "Вывод на банковскую карту",
  },
  {
    value: "cryptocurrency",
    label: "Вывод на криптокошелек",
  },
  {
    value: "sbp",
    label: "Вывод по СБП",
  }
]

function ModalWithdraw() {
  const dispatch = useDispatch();

  const [phone, setPhone] = useState('');
  const [cardNumber, setCardNumber] = useState('');
  const [sum, setSum] = useState('');
  const [comment, setComment] = useState('');
  const [cryptoNumber, setCryptoNumber] = useState('');
  const [type, setType] = useState({
    value: "card",
    label: "Вывод на банковскую карту",
  });

  const [open, setOpen] = useState(false);

  const snackbarActions = (snackText, error) => {
    dispatch(snackbarAction({snackText, error}))
    setTimeout(() => {
      dispatch(snackbarClearAction())
    }, 2000);
  };

  const selectStyles = (open) => ({
    singleValue: (provided) => ({
      ...provided,
      color: 'rgba(255, 255, 255, 0.6)',
      background: '#10162c',
    }),
    menu: (provided) => ({
      ...provided,
      height: open ? "120px" : "0px",
      overflow: "hidden",
      opacity: open ? 1 : 0,
      transition: "all 0.3s ease-in-out",
      visibility: open ? "visible" : "hidden",
      background: '#10162c',
      color: 'rgba(255, 255, 255, 0.6)',
    }),
    control: (styles) => ({
      ...styles,
      backgroundColor: '#10162c',
      color: 'rgba(255, 255, 255, 0.6)',
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
        ? undefined
        : isSelected
        ? '#191d3e'
        : isFocused
        ? 'rgb(123 128 152)'
        : undefined,
        color: 'rgba(255, 255, 255, 0.6)',
        cursor: 'pointer',
      };
    },
  });

  const refOutputPOST = () => {
    mainApi
      .refOutputPOST({
        "sum": sum,
        ...(comment ? { "comment": comment } : undefined),
        ...(cardNumber ? { "card_number": cardNumber } : undefined),
        ...(phone ? { "phone": phone } : undefined),
        ...(cryptoNumber ? {"crypto_number": cryptoNumber} : undefined),
        "type": type.value,
      })
      .then((data) => {
        snackbarActions('Вывод прошел')
        dispatch(modalWithdrawClearAction())
      })
      .catch((error) => {
        console.log("error", error);
        snackbarActions("Ошибка", error);
      });
    
  }

  return (
    <div className="modal_overlay">
      <div className="modal_wrapper_template">
        <div className="modal_template login_modal">
          <div className="modal_header_shop">
            <span className="modal_header_shop-name">Вывод реф. баланса</span>
            <button
              className="modal_closer"
              onClick={() => dispatch(modalWithdrawClearAction())}
            >
              <ExitIcon />
            </button>
          </div>

<div className="aouth_login_inputs">
<div
    onClick={() => setOpen(!open)}
    className="modal_select_withdraw"
    style={{
      marginBottom: "15px"
    }}

  >
    <Select
      options={option}
      className="conditions_select_box select_pagination_withdraw"
      onChange={setType}
      onBlur={() => setOpen(false)}
      value={type}
      menuIsOpen
      styles={selectStyles(open)}
      menuPlacement="top"
    />
</div>

  <div className="auth_password_input">
    <input
      type="text"
      className="aouth_login_input"
      placeholder="Сумма вывода"
      value={formatNumber(sum)} 
      onChange={(e) => {
        const input = e.target.value;
        if (/^\d*$/.test(input.replace(/\s/g, ''))) {
          setSum(e.target.value.replace(/\s/g, ''));
        }
      }}
    />
  </div>

  {type.value === "card" && (
    <div className="auth_password_input">
      <input
        type="text"
        className="aouth_login_input"
        placeholder="Номер карты"
        value={cardNumber}
        onChange={(e) => setCardNumber(e.target.value)}
      />
    </div>
  )}

  {type.value === "cryptocurrency" && (
    <div className="auth_password_input">
      <input
        type="text"
        className="aouth_login_input"
        placeholder="Номер криптокошелька"
        value={cryptoNumber}
        onChange={(e) => setCryptoNumber(e.target.value)}
      />
    </div>
  )}

  {type.value === "sbp" && (
    <div className="auth_password_input">
      <input
        type="text"
        className="aouth_login_input"
        placeholder="Номер телефона (СБП)"
        value={phone}
        onChange={(e) => setPhone(e.target.value)}
      />
    </div>
  )}

  <div className="auth_password_input">
    <input
      type="text"
      className="aouth_login_input"
      placeholder="Комментарий"
      value={comment}
      onChange={(e) => setComment(e.target.value)}
    />
  </div>

</div>



          <div className="modal_btns_shop">
            <button className="green_btns" onClick={refOutputPOST}>
              Подтвердить
            </button>
            <button className="orange_btns_shop" onClick={() => dispatch(modalWithdrawClearAction())}>
             Отклонить
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalWithdraw;
