import React, { useEffect } from "react";

import "./agreement.css"

const Agreement = () => {
  useEffect(() => {
    document.title = "Пользовательское соглашение";
  }, []);

  return (
    <div className="agreement-page">
<h1 className="agreement-h1">Пользовательское соглашение</h1>
<p>Настоящие положения пользовательского соглашения (далее — «Соглашение») регулируют отношения между пользователем и компанией PL, а также с её аффилированными лицами (далее — «PL» или «мы») в контексте использования интернет-ресурса primoloot.com (далее — «Сервис»).</p>
<p className="agreement-label">Общие условия</p>
<p>Для продолжения использования Сервиса, включая просмотр его страниц, Пользователь обязуется принять условия настоящего Соглашения. Для использования Сервиса необходимо создать учётную запись (далее — «учётная запись»). Регистрируя учётную запись, Пользователь подтверждает, что является дееспособным совершеннолетним гражданином Российской Федерации и принимает условия Соглашения в полном объёме. Если учётная запись создаётся через сторонние интернет-ресурсы (например, VK.com), Пользователь также обязуется соблюдать условия пользовательского соглашения этих ресурсов наряду с условиями настоящего Соглашения.</p>
<p>УСТАНАВЛИВАЯ, ИСПОЛЬЗУЯ ИЛИ ИНЫМ ОБРАЗОМ ПОЛУЧАЯ ДОСТУП К СЕРВИСУ, ПОЛЬЗОВАТЕЛЬ ПОДТВЕРЖДАЕТ СВОЁ СОГЛАСИЕ С УСЛОВИЯМИ ДАННОГО ПОЛЬЗОВАТЕЛЬСКОГО СОГЛАШЕНИЯ. ЕСЛИ ПОЛЬЗОВАТЕЛЬ НЕ СОГЛАСЕН С УСЛОВИЯМИ, ОН ДОЛЖЕН ПРИОСТАНОВИТЬ ИСПОЛЬЗОВАНИЕ СЕРВИСА, ВКЛЮЧАЯ ОТКРЫТИЕ СТРАНИЦ СЕРВИСА. ИСПОЛЬЗОВАНИЕ СЕРВИСА НЕВОЗМОЖНО В ТЕХ СТРАНАХ И СИТУАЦИЯХ, ГДЕ ОНО ПРОТИВОРЕЧИТ МЕСТНОМУ ЗАКОНОДАТЕЛЬСТВУ.</p>
<p>PL оставляет за собой право в любое время изменять, обновлять или дополнять условия Соглашения, опубликовав новые условия на страницах Сервиса. В случае продолжения использования Сервиса после внесения изменений, PL может считать, что Пользователь выразил согласие с новыми условиями. Если Пользователь не согласен с изменениями, лицензия на использование Сервиса прекращается немедленно. В таком случае Пользователь обязуется прекратить использование Сервиса.</p>
<p></p>
<p className="agreement-label">1. Лицензия на использование Сервиса</p>
<p className="agreement-label">1.1. Предоставление ограниченной лицензии</p>
<p>Согласившись с условиями Соглашения, PL предоставляет Пользователю неисключительную, непередаваемую и не подлежащую сублицензированию лицензию для использования Сервиса в личных, некоммерческих целях, с учётом нижеследующих ограничений. Пользователь обязуется не использовать Сервис в иных целях.</p>
<p className="agreement-label">2. Учетная запись</p>
<p>2.1. Пользователь несёт полную ответственность за безопасность пароля и других данных, используемых для доступа к Сервису. Для регистрации необходимо указать действующий адрес электронной почты и создать уникальный пароль, состоящий из букв латинского алфавита (в разных регистрах) и цифр. PL не несет ответственности за убытки, возникшие из-за утечки данных, связанной с действиями Пользователя или разглашением пароля и других конфиденциальных данных.</p>
<p>2.2. Пользователь обязуется не использовать Сервис, если ему не исполнилось 18 лет. Пользователь обязуется принять меры для предотвращения использования Сервиса несовершеннолетними и несет полную ответственность за использование своей банковской карты или других платежных инструментов несовершеннолетними.</p>
<p>2.3. Принимая это Соглашение, Пользователь подтверждает, что ему не менее 18 лет и обязуется предотвратить использование Сервиса несовершеннолетними. Он также несет ответственность за использование своей банковской карты или других средств оплаты несовершеннолетними.</p>
<p>2.4. Пользователь обязуется не разглашать, не продавать и не передавать данные учётной записи третьим лицам, не создавать учётную запись под чужим именем или с подложными данными. В случае, если учётная запись была удалена или заблокирована по решению PL, пользователь обязан воздержаться от использования Сервиса.</p>
<p>2.5. Пользователь обязуется использовать учётную запись исключительно в некоммерческих целях. Запрещено использовать учётную запись для распространения коммерческих предложений.</p>
<p>2.6. Пользователь обязуется предотвратить несанкционированный доступ к своей учётной записи. В случае подозрений на компрометацию данных учётной записи, он обязан незамедлительно сообщить об этом PL. Пользователь несёт ответственность за все действия, связанные с использованием учётной записи, независимо от того, было ли оно санкционировано.</p>
<p>2.7. PL оставляет за собой право удалять или изменять данные учётной записи по любому поводу, включая, но не ограничиваясь, нарушением прав третьих лиц.</p>
<p>2.8. Пользователь обязуется использовать только одну учётную запись. Создание и использование нескольких учётных записей для одного физического лица запрещено. PL имеет право заблокировать или удалить учётные записи, принадлежащие одному лицу.</p>
<p>2.9. Использование Сервиса с нарушением установленных Лицензионных ограничений является запрещённым и может привести к немедленному отзыву лицензии на использование Сервиса, а также к ответственности за нарушение Соглашения.</p>
<p>2.10. Пользователь соглашается не совершать следующие действия:</p>
<p>• Не предпринимать шаги, которые могут быть расценены PL как противоречащие целям или использованию Сервиса, либо приводящие к ненадлежащему функционированию поддерживаемых устройств PL. </p>
<p>• Не использовать уязвимости системы, несанкционированное программное обеспечение для взлома или вмешательства в работу Сервиса. </p>
<p>• Не изменять или распространять интеллектуальную собственность PL без разрешения. </p>
<p>• Не создавать, не поддерживать или не участвовать в компьютерных атаках, в том числе распространении вредоносного ПО. </p>
<p>• Не пытаться получить несанкционированный доступ к Сервису или личным данным других пользователей. </p>
<p>• Не размещать информацию, нарушающую законы, или информацию, которая может привести к разжиганию ненависти или нарушению прав других людей. </p>
<p>• Не размещать или передавать материалы, нарушающие авторские права или другие права на интеллектуальную собственность. </p>
<p>• Не использовать Сервис для распространения запрещенной или ограниченной информации. </p>
<p>• Не участвовать в распространении материалов, связанных с террористической деятельностью или пропагандой насилия. </p>
<p>• Не пытаться извлечь исходный код программного обеспечения или интеллектуальной собственности Сервиса. </p>
<p>• Не собирать или распространять личную информацию других пользователей без их согласия.</p>
<p>2.11. PL оставляет за собой право оценивать действия Пользователя и принимать меры, если их поведение нарушает правила Соглашения, что может привести к блокировке учётной записи или запрету на использование Сервиса.</p>
<p className="agreement-label">3. Приостановление действия и прекращение учетной записи и Сервиса</p>
<p className="agreement-label">3.1. Прекращение и ограничение учетной записи</p>
<p>PL имеет право, не ограничиваясь другими мерами правовой защиты, ограничить, приостановить, прекратить, изменить или удалить учетные записи Пользователей или доступ к Сервису или его частям, если PL предполагает, что Пользователь нарушает условия настоящего Соглашения или использует Сервис незаконно или ненадлежащим образом. Это может быть сделано с предварительным уведомлением или без такового. В случае прекращения или ограничения учетной записи Пользователь может потерять доступ к своей учетной записи, а также ко всем накопленным бонусам и предметам, полученным через использование Сервиса, при этом PL не несет ответственности за возможные убытки или последствия.</p>
<p>PL также вправе ограничить, приостановить или прекратить доступ к Сервису или учетным записям Пользователей, удалить размещённый контент, а также принять технические и юридические меры для предотвращения доступа, если действия Пользователей создают риск или возможность юридической ответственности, нарушают интеллектуальную собственность третьих лиц или противоречат условиям Соглашения и политики PL. В случае повторных нарушений интеллектуальной собственности мы можем приостановить или прекратить учетные записи, нарушающие права третьих лиц.</p>
<p className="agreement-label">3.2. Неактивные учетные записи</p>
<p>PL оставляет за собой право закрыть учетные записи, которые не использовались в течение 180 дней. Также, мы можем прекратить предложение Сервиса или его части в любое время, что приведет к автоматическому прекращению лицензии на использование Сервиса. В таком случае PL не несет обязательств по компенсации Пользователям за прекращение доступа к Сервису по причинам, не зависящим от компании.</p>
<p className="agreement-label">3.3. Прекращение доступа к Сервису</p>
<p>Прекращение действия учетной записи может привести к блокировке доступа Пользователя ко всем частям Сервиса или его отдельным частям.</p>
<p className="agreement-label">3.4. Право Пользователя на прекращение учетной записи</p>
<p>Пользователь может прекратить действие своей учетной записи в любой момент, по любому основанию, написав в чат поддержки или отправив запрос на электронную почту primoloot@proton.me.</p>
<p></p>
<p className="agreement-label">4. Исключительные права</p>
<p className="agreement-label">4.1. Сервис</p>
<p>Все права на Сервис принадлежат PL. Все использованные товарные знаки являются собственностью соответствующих правообладателей.</p>
<p className="agreement-label">4.2. Учетные записи</p>
<p >Независимо от любых других положений, Пользователь признает и соглашается с тем, что он не обладает правами или имущественными интересами на свою Учетную запись. Все права на Учетную запись на данный момент и в будущем, без ограничений по времени, принадлежат исключительно PL.</p>
<p className="agreement-label">4.3. Материалы и контент Сервиса</p>
<p>PL обладает исключительными правами на все материалы, размещенные в Сервисе, включая, но не ограничиваясь, программным кодом, виртуальными предметами, текстами, фотографиями и элементами дизайна. Пользователь соглашается с тем, что у него нет исключительного права или собственности на любой контент, представленный в Сервисе, включая виртуальные предметы или валюту, используемую или получаемую через Сервис, независимо от того, были ли они приобретены от PL или получены иным способом. Все объекты, связанные с Учетной записью и Сервисом, остаются собственностью PL.</p>
<p className="agreement-label">5.1. Покупки</p>
<p>5.1.1. В рамках Сервиса Пользователь имеет возможность приобрести ограниченную, личную, непередаваемую лицензию на использование цифровых объектов, включая, но не ограничиваясь:</p>
<p>(a) «виртуальной валютой» (далее - “Мора”), которая представляет собой виртуальные деньги, отображаемые на балансе пользователя и предназначенные для использования в Сервисе;</p>
<p>(b) «виртуальные предметы», такие как игровое снаряжение;</p>
<p>(c) другие товары или услуги («Товары»).</p>
<p>5.1.2. PL имеет право управлять, регулировать, изменять или удалять Виртуальные предметы и/или Товары в любой момент, с предварительным уведомлением или без такового. PL не несет ответственности перед Пользователем или любыми третьими лицами за реализацию этих прав.</p>
<p>5.1.3. Лицензии, передаваемые на приобретаемую виртуальную валюту и виртуальные предметы, предоставляются на экстерриториальной основе (по всему миру) на весь срок их использования в соответствии с законодательством, если иное не указано в условиях покупки таких объектов.</p>
<p>5.1.4. За исключением случаев, прямо предусмотренных Сервисом, Пользователь не имеет права продавать, обменивать или передавать Мору или Товары любому физическому или юридическому лицу, включая, но не ограничиваясь, PL, другими пользователями или третьими лицами.</p>
<p>5.1.5. Все покупки и погашения виртуальной валюты третьими лицами, совершенные через Сервис, являются окончательными и не подлежат возврату.</p>
<p>5.1.6. Предоставление Виртуальных предметов через платформу primoloot.com является частью сервиса PL, который начинается сразу после принятия пользователем предложения о покупке. Услуги считаются полностью оказанными с момента предоставления Пользователю Виртуальных предметов.</p>
<p>5.1.7. PL гарантирует выполнение оплаченной услуги, включая «доставку» приобретенных Товаров на Учетную запись Пользователя в игре Genshin Impact, в соответствии с настоящим Соглашением и пользовательским соглашением правообладателя Genshin Impact.</p>
<p>5.1.8. PL гарантирует, что использование Сервиса и приобретенные товары или Мора не приведут к негативным последствиям для учетной записи Пользователя в игре Genshin Impact, включая блокировку Учетной записи или ухудшение положения Пользователя в игре.</p>
<p>5.1.9. Все транзакции в Сервисе осуществляются с использованием Моры.</p>
<p className="agreement-label">5.3. Осуществление платежей</p>
<p>5.3.1. Пользователь соглашается оплатить все платежи, а также применимые налоги, возникающие как на стороне Пользователя, так и на стороне лиц, использующих зарегистрированную Учетную запись.</p>
<p>5.3.2. Пользователь соглашается оплатить все комиссии банков и сторонних платежных сервисов, если эти комиссии не включены в стоимость товара и услуг, а также возникающие расходы на стороне Пользователя или лиц, использующих Учетную запись.</p>
<p>5.4. PL оставляет за собой право изменять стоимость товаров и услуг, предоставляемых через Сервис, в любое время.</p>
<p>5.5. Пользователь признает, что PL не обязана предоставлять компенсацию по какой-либо причине, и Пользователь не имеет права требовать возврат денег или другой компенсации за неиспользованные виртуальные предметы, если Учетная запись Пользователя была закрыта.</p>
<p className="agreement-label">6. Ограничение ответственности</p>
<p className="agreement-label">6.1. Без ограничения ответственности PL согласно разделу 6 ниже, Сервис предоставляется для вашего использования «как есть» и «как он доступен», без каких-либо прямых или подразумеваемых гарантий, включая, но не ограничиваясь, гарантиями соответствия ожиданиям покупателя, соответствия определенной цели, титула, отсутствия нарушений, а также тех гарантий, которые могут возникать из деловых обычаев. PL не гарантирует, что Пользователь будет иметь возможность доступа к Сервису или его использования в любое время или в любом месте по своему усмотрению, что работоспособность Сервиса не будет нарушаться, а также что Сервис не будет содержать ошибок или что все недостатки будут исправлены.</p>
<p>6.2. PL не несет ответственности за содержание страниц, представленных третьими лицами и/или за содержание внешних сайтов.</p>
<p className="agreement-label">7. Разрешение споров и применимое право</p>
<p>В случае возникновения спора между Пользователем и PL, мы настоятельно рекомендуем Пользователю сначала связаться непосредственно с нами через нашу службу поддержки по адресу primoloot@proton.me.</p>
<p>7.1. Обращаясь в службу поддержки PL, Пользователь обязуется действовать добросовестно и в соответствии с положениями действующего законодательства. В случае, если сотрудниками поддержки будут выявлены недобросовестные действия Пользователя (или подозрения на такие действия), направленные на введение PL в заблуждение с целью получения материальной выгоды или по иным причинам, PL вправе в одностороннем порядке расторгнуть настоящее Соглашение и заблокировать учётную запись Пользователя без предоставления компенсации.</p>
<p>7.2. Если Пользователь является резидентом другого государства, он соглашается с тем, что любой спор между Пользователем и PL будет регулироваться правом Эстонской Республики, без учета коллизионных норм. Пользователь соглашается с тем, что любое требование или спор, который может быть инициирован против PL, подлежит исключительной юрисдикции суда, расположенного в Эстонской Республике.</p>
<p className="agreement-label">8. Раздельность положений</p>
<p>Пользователь и PL соглашаются с тем, что если любая часть настоящего Пользовательского соглашения или Политики конфиденциальности PL будет признана компетентным судом недействительной или не подлежащей защите, полностью или частично, то только такая часть соглашения, признанная недействительной или не подлежащей защите, будет считаться недействительной в данной юрисдикции, и только в той части, в которой она была признана таковой. Это не повлияет на действительность или возможность защиты остальных частей настоящего Пользовательского соглашения, которые сохраняют свою полную силу и действие.</p>
<p className="agreement-label">9. Иные условия</p>
<p className="agreement-label">9.1. Уступка</p>
<p>PL вправе уступить или передать настоящее Пользовательское соглашение полностью или частично любому физическому или юридическому лицу в любое время, с согласия Пользователя или без такового. Пользователь не имеет права уступать или передавать свои права и обязанности, вытекающие из Пользовательского соглашения, без предварительного письменного согласия PL. Любая уступка или передача прав без такого согласия будет недействительной.</p>
<p className="agreement-label">9.2. Дополнительные политики</p>
<p>PL может публиковать дополнительные политики, относящиеся к определенным сервисам, таким как форумы, состязания или программы лояльности. Право Пользователя использовать такие сервисы определяется такими определенными политиками и настоящим Пользовательским соглашением.</p>
<p className="agreement-label">9.3. Полнота соглашения</p>
<p>Настоящее Пользовательское соглашение, любые дополнительные политики и иные документы, прямо включенные в них посредством отсылки, содержат полную договоренность между Пользователем и PL. Они имеют приоритет и отменяют все предшествующие договоренности сторон, относящиеся к предмету настоящего соглашения, будь они сделаны в электронной, устной или письменной форме, или установлены обычаем, практикой, политикой или прецедентом.</p>
<p className="agreement-label">9.4. Отсутствие отказа</p>
<p>Невозможность со стороны PL требовать или принуждать Пользователя к строгому исполнению любого положения настоящего Пользовательского соглашения, либо невозможность реализовать любое установленное право не может быть истолкована как отказ от этого права или освобождение от обязательств. Прямой отказ PL от любого положения соглашения не образует отказ от последующих требований. Если иное не указано в соглашении, никакие заявления или действия PL не могут изменять настоящее соглашение, за исключением случаев, когда они выражены в письменной форме с подписями сторон.</p>
<p className="agreement-label">9.5. Уведомления</p>
<p>Мы можем уведомлять Пользователя, размещая информацию на сайте primoloot.com, а также через электронную почту или любой другой способ связи в соответствии с предоставленной контактной информацией (например, аккаунт в социальной сети “VK.COM” или TELEGRAM).</p>
<p className="agreement-label">9.6. Справедливые средства правовой защиты</p>
<p>Пользователь признает, что определенные права и обязанности по настоящему соглашению уникальны и не могут быть заменены денежным возмещением. Нарушение этих обязательств причиняет непоправимый вред PL, что дает право на судебное предписание или другое средство правовой защиты. Пользователь отказывается от требования предписания или других средств правовой защиты, а также от наложения запрета или ограничения на Сервис, с согласием на ограничение требований исключительно возмещением денежного ущерба.</p>
<p className="agreement-label">9.7. Форс-мажор</p>
<p>PL не несет ответственности за задержки или невозможность исполнения обязательств, вызванных обстоятельствами, находящимися за пределами разумного контроля PL, включая стихийные бедствия, войны, терроризм, восстания, эмбарго, акты гражданских или военных властей, пожары, наводнения, несчастные случаи, забастовки или недостатки ресурсов. PL обязан обосновать невозможность исполнения обязательств в публичном письме.</p>
<p className="agreement-label">10. Политика возврата средств</p>
<p>10.1. Возврат денежных средств Пользователю со стороны PL возможен только в случае невозможности или ненадлежащего выполнения PL условий Соглашения по передаче Пользователю "виртуальных предметов", за исключением случаев, указанных в данном Соглашении.</p>
<p>10.1.1. Ошибки, связанные с некорректным вводом данных Пользователем для перечисления "виртуальных предметов", начисление "виртуальных предметов" на учетную запись третьих лиц, а также другие ошибки, возникшие по вине Пользователя, не являются основанием для возврата денежных средств.</p>
<p>10.1.2. Как только "виртуальные предметы" поступают на баланс Пользователя в игре, обязательства PL по передаче этих предметов считаются выполненными в полном объеме.</p>
<p>10.2. Для получения возврата уплаченных средств, Пользователь должен выполнить следующие действия:</p>
<p>а) предоставить PL подтверждение оплаты услуги (чек) в разумный срок;</p>
<p>б) предоставить PL доказательства, что "виртуальные предметы" не были зачислены на баланс, в виде видеофиксации актуальной истории транзакций в игре, с подтверждением отсутствия вмешательства в код страницы или других методов фальсификации;</p>
<p>в) предоставить PL реквизиты банковского счета или другого платежного сервиса, через который была проведена оплата.</p>
<p>Все указанные доказательства необходимо направить на электронную почту primoloot@proton.me в разумные сроки.</p>
<p>10.3. В случае возникновения спорной ситуации, PL вправе запросить у Пользователя дополнительные доказательства осуществленной оплаты и непоступления "виртуальных предметов". Пользователь обязуется предоставить такие доказательства и принимает на себя ответственность за их подлинность и актуальность.</p>
<p>10.4. PL вправе отклонить представленные доказательства, если возникнут сомнения в их достоверности, законности или применимости для разрешения спора.</p>
<p>11. Досудебное урегулирование споров</p>
<p>11.1. Если Пользователь намерен обратиться в суд для защиты своих прав в связи со спором с PL, он обязан сначала направить PL досудебную претензию. В ней должны быть указаны полные основания для обращения в суд и изложены все детали спорной ситуации. Претензия и все сопутствующие документы должны быть направлены на электронную почту primoloot@proton.me.</p>
<p>11.2. Пользователь обязуется воздержаться от обращения в суд до получения официального ответа от PL на направленную досудебную претензию.</p>
<p className="agreement-label">12. Примечание</p>
<p>Сайт не связан с MihoYo и HoyoVerse и не отражает мнения или взгляды MihoYo и HoyoVerse или любых других организаций, участвующих в производстве или управлении их продуктами. MihoYo и HoyoVerse и все их объекты являются товарными знаками или зарегистрированными товарными знаками компании miHoYo Co., Ltd.</p>
    </div>
  );
};

export default Agreement;
