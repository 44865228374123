import React from "react";
import { formatNumber } from "../../helpers/price";

const ItemCardUser = React.memo(({ item }) => {
  return (
<div className="user_page_crystal">
    <div className="user_page_crystal_top">
      <div className="user_page_crystal_top_state">
        <p>{item.status}</p>
      </div>
      <div className="user_page_crystal_top_cost">
        <p>{formatNumber(item.price)} ₽</p>
      </div>
    </div>

      <img src={item.image} className="user_page_image_items" alt="" />
      <div className="user_page_crystal_descr">
        <p>{item.name}</p>
      </div>
</div>
  );
});

export default ItemCardUser;