import React, { lazy, Suspense, useEffect, useState } from "react";
import "./main-page.css";
import CasesCharacters from "../../components/cases-characters/cases-characters"


function MainPage() {
  useEffect(() => {
    document.title = "Выигрывай примогемы Genshin Impact | Primoloot.com";
  }, []);

  return (
    <div className="page_template home_page_wrapper">

        <CasesCharacters />

    </div>
  );
}

export default MainPage;