import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

import { mainApi } from "../utils/main-api";
import { formatNumber } from "../../helpers/price";

import crystal from "../../assets/icons/crystal-icon.png";
import logo from "../../assets/main-logo.svg";
import { ReactComponent as YoutubeIcon } from "../../assets/icons/footer-socials/youtube.svg";
import { ReactComponent as VkIcon } from "../../assets/icons/footer-socials/vk.svg";
import { ReactComponent as TikTokIcon } from "../../assets/icons/footer-socials/tik-tok.svg";
import { ReactComponent as TGIcon } from "../../assets/icons/footer-socials/tg.svg";

import "./footer.css";

function Footer() {
  const [active, setActive] = useState(false);
  const [analytics, setAnalytics] = useState();

  useEffect(() => {
    mainApi
      .getAnalyticsFooter()
      .then((res) => {
        setAnalytics(res);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, [])

  useEffect(() => {
    if (localStorage.getItem("token")) {
      setActive(true);
    } else {
      setActive(false);
    }
  }, [localStorage.getItem("token")]);

  useEffect(() => {
    if (active) {
      let socket = new WebSocket("wss://server.primoloot.com/ws/online");
    }
  }, [active]);
  
  return (
    <footer>
      <div className="footer_top_stats_wrapper">
        <div className="footer_top_stats page_template">
          <div className="footer_stat">
            <p>{formatNumber(analytics?.opened_cases)}</p>
            <span>Открыто кейсов</span>
          </div>
          <div className="footer_stat">
            <p>{formatNumber(analytics?.total_users)}</p>
            <span>Пользователей</span>
          </div>
          <div className="footer_stat">
            <p>{formatNumber(analytics?.users_online)}</p>
            <span>Онлайн</span>
          </div>
          <div className="footer_stat">
            <p>{formatNumber(analytics?.total_purchase)}</p>
            <span>Покупок в магазине</span>
          </div>
          <div className="footer_stat crystals_stat">
            <div>
              <img src={crystal} alt="" />
              <p>{formatNumber(analytics?.total_crystal)}</p>
            </div>

            <span>Кристаллов выведено</span>
          </div>
        </div>
      </div>

      <div className="main_footer_wrapper">
        <div className="footer_wrapper page_template">
          <div className="footer_logo">
            <NavLink to="/">
              <img src={logo} alt=""  width="200"  />
            </NavLink>
          </div>
          <div className="footer_menu">
            <div className="footer_menu_item">
              <NavLink to="/profile/affiliate-program">
                <p>Партнерская программа</p>
              </NavLink>
            </div>
            <div className="footer_menu_item">
              <NavLink to="/agreement">
                <p>Соглашение</p>
              </NavLink>
            </div>
            <div className="footer_menu_item">
              <NavLink to="/privacy">
                <p>Политика конфендициальности</p>
              </NavLink>
            </div>
            <div className="footer_menu_item">
              <NavLink to="/faq">
                <p>FAQ</p>
              </NavLink>
            </div>
          </div>
          <div className="footer_socials">
            <a href="#" className="grey_icon">
              <YoutubeIcon />
            </a>
            <a href="#" className="grey_icon">
              <VkIcon />
            </a>
            <a href="#" className="grey_icon">
              <TikTokIcon />
            </a>
            <a href="#" className="grey_icon">
              <TGIcon />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
