import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import card2 from "../../assets/images/topup/card-2.png";
import card16 from "../../assets/images/topup/card-16.png";
import deposit_bonus_img from "../../assets/images/bonus-topup-img.png";
import deposit_bonus from "../../assets/images/deposit-bonus-banner.png";
import {
  snackbarAction,
  snackbarClearAction,
} from "../../redux/snackbar-reducer";
import { mainApi } from "../../components/utils/main-api";
import { ReactComponent as Money } from "../../assets/icons/topup-money.svg";
import { ReactComponent as Wallet } from "../../assets/icons/wallet-icon.svg";
import "./topup.css";

const topup_filter = [
  { types: "Все" },
  { types: "Банковские карты" },
  { types: "Эл. кошельки" },
];

const topup_items = [
  {
    type: "Банковские карты",
    img: card2,
    name: "visa/mir",
    type_payments: "freekassa",
    include_service: "36",
  },
  {
    type: "Банковские карты",
    img: card16,
    name: "SBP (NSPC)",
    type_payments: "freekassa",
    include_service: "44",
  },
];

function Topup() {
  const [promoCode, setPromoCode] = useState(""); // Промокод
  const [topupTypes, setTopupTypes] = useState(topup_items);
  const [isPaymentTypes, setIsPaymentTypes] = useState(false);
  const [selectedMethod, setSelectedMethod] = useState("");
  const [activeFilter, setActiveFilter] = useState("");
  const [topupAmount, setTopupAmount] = useState(3000);
  const [email, setEmail] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [promoStatusMessage, setPromoStatusMessage] = useState("");
  const [promoStatusError, setPromoStatusError] = useState(false);

  const [promoBonusPercentage, setPromoBonusPercentage] = useState(0);


  const usersData = useSelector((state) => state.user.user);

  const snackbarActions = (snackText, error = false) => {
    dispatch(snackbarAction({ snackText, error }));
    setTimeout(() => {
      dispatch(snackbarClearAction());
    }, 2000);
  };

  const filteredTopupTypes = (type) => {
    setActiveFilter(type);
    const filtered = topup_items.filter((item) => item.type === type);
    setTopupTypes(type === "Все" ? topup_items : filtered);
    setIsPaymentTypes(type === "Платежи");
  };

  const replenish = () => {
    if (!email) {
      snackbarActions("Введите email", true);
    } else if (!selectedMethod) {
      snackbarActions("Выберите метод оплаты", true);
    } else {
      mainApi
        .paymentsPOST({
          email,
          type_payments: selectedMethod.type_payments,
          sum: parseFloat(topupAmount),
          include_service: selectedMethod.include_service,
        })
        .then(() => {
          snackbarActions("Баланс успешно пополнен!");
        })
        .catch((error) => {
          snackbarActions("Ошибка пополнения", true);
          console.error(error);
        });
    }
  };

const activatePromoCode = () => {
  if (!promoCode) {
    snackbarActions("Введите промокод", true);
    setPromoStatusMessage("Введите промокод");
    setPromoStatusError(true);
    return;
  }

  mainApi
    .promoPOST({ code_data: promoCode })
    .then((res) => {
      const { code, message } = res;
      // setPromoCode(code || "");
      snackbarActions(message || "Промокод активирован!");
      setPromoStatusMessage(message || "Промокод активирован!");
      setPromoStatusError(false);

      // Извлекаем процент бонуса из сообщения
      const bonusMatch = message.match(/Бонус к пополнению \+(\d+(\.\d+)?)%/);
      if (bonusMatch) {
        const bonusPercent = parseFloat(bonusMatch[1]);
        setPromoBonusPercentage(bonusPercent);
      } else {
        setPromoBonusPercentage(0);
      }
    })
    .catch((error) => {
      console.error("Ответ ошибки от сервера:", error.response?.data);
    
      const errorMessage = error.response?.data?.message || error.message || "Ошибка активации промокода";
      setPromoStatusMessage(errorMessage);
      setPromoStatusError(true);
      snackbarActions(errorMessage, true);

      // Сбрасываем процент бонуса при ошибке
      setPromoBonusPercentage(0);
    
      const errorNotFined = error.response?.data?.non_field_errors || error.non_field_errors || errorMessage;
      snackbarActions(errorNotFined, true);
      
    });
};

  useEffect(() => {
    document.title = `Пополнение баланса - Primoloot.com`;
  }, []);

  useEffect(() => {
    if (!usersData?.is_logged) {
      navigate("/");
    }
  }, [navigate, usersData]);

  const content = useMemo(() => {
    return isPaymentTypes ? (
      <p>Платежные методы пока недоступны</p>
    ) : (
      <div className="topup_cards_list">
        {topupTypes.map((item) => (
          <div
            key={item.name}
            onClick={() => setSelectedMethod(item)}
            className={`topup_cards_list_item ${
              selectedMethod.name === item.name ? "selected_topup_type" : ""
            }`}
          >
            <img src={item.img} alt={item.name} />
          </div>
        ))}
      </div>
    );
  }, [isPaymentTypes, selectedMethod, topupTypes]);

  const creditedAmount = useMemo(() => {
    const amount = parseFloat(topupAmount) || 0;
    const bonusMultiplier = 1 + promoBonusPercentage / 100;
    return amount * bonusMultiplier;
  }, [topupAmount, promoBonusPercentage]);

  return (
    <div className="page_template topup_page">
      <div className="page_title">
        <h1>Пополнение баланса</h1>
      </div>
      <div className="topup_filters">
        {topup_filter.map((item) => (
          <div
            key={item.types}
            className={`topup_filter_item ${
              activeFilter === item.types ? "active_topup_filter" : ""
            }`}
            onClick={() => filteredTopupTypes(item.types)}
          >
            {item.types}
          </div>
        ))}
      </div>
      <div className="topup_content">
        <div className="topup_cards_list_wrapper">{content}</div>
        <div className="topuping_content">
          <div className="bonus_promocode_topup">
            <div className="topup_bonus_wrapper">
              {promoStatusMessage && !promoStatusError && (
                <div className="topup_bonus">
                  <p className="promo_status_message success_message">
                    {promoStatusMessage.split("\n").map((line, index, array) => (
                      <React.Fragment key={index}>
                        <span className={index === 1 ? "bonus_line" : ""}>
                          {line.replace(/(\d+)\.0%/, "$1%")}
                        </span>
                        {index < array.length - 1 && <br />}
                      </React.Fragment>
                    ))}
                  </p>
                </div>
              )}
  
              <div className="activate_promocode">
                <input
                  className="topup_bonus_input"
                  type="text"
                  placeholder="Введите промокод"
                  value={promoCode}
                  onChange={(e) => setPromoCode(e.target.value)}
                />
                <button
                  className="activate-promo_button"
                  onClick={activatePromoCode}
                >
                  Применить
                </button>
              </div>
              <input
                className="topup_bonus_input"
                type="email"
                placeholder="Введите email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>
          <div className="topup_block">
            <div className="topup_block_title">
              <p>Введите сумму пополнения</p>
            </div>
            <div className="topup_amount">
              <Money />
              <input
                type="text"
                value={topupAmount}
                onChange={(e) => {
                  const value = e.target.value.replace(/\D/g, "");
                  setTopupAmount(value ? parseFloat(value) : "");
                }}
                placeholder="Сумма"
              />
            </div>

            {/* Отображаем итоговую сумму с бонусом */}
            {topupAmount && (
              <p className="credited_amount_info">
                На баланс поступит: <strong>{creditedAmount.toFixed(2)} ₽</strong>
              </p>
            )}

            <button
              className="topup_btn orange_btn"
              onClick={replenish}
              disabled={!topupAmount || !selectedMethod}
            >
              Пополнить <Wallet />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Topup;
