import React from "react";
import "./case-item.css";

const CaseItem = React.memo(({ item, isActive }) => {
  return (
    <div 
    className={isActive ? "case_item case_item_active" : "case_item"}
    >
      <img src={item.image} alt="" className="case_item_img" />
      <p>{item.name}</p>
      <span>{item.price} ₽</span>
    </div>
  );
});

export default CaseItem;